/***
 *
 *   CONTACT FORM
 *   Will send an email notification to you
 *
 **********/

import React from 'react'
import { Row, ContactForm } from 'components/lib'
import { Helmet } from 'react-helmet-async'

export function Contact(props) {
  return (
    <Row title="Contact Us">
      <Helmet>
        <meta
          name="description"
          content="Need assistance with Law Cyborg? Contact our support team and get quick help with using our AI-powered tax research tools. We're here to ensure your success."
        />
      </Helmet>
      <ContactForm />
    </Row>
  )
}
