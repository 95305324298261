import { useEffect, useRef, useCallback } from 'react'

export function useConversationAutoScroll(ref, autoScroll, setAutoScroll, dependency) {
  const prevScrollTopRef = useRef(0)

  const handleUserScroll = useCallback(() => {
    const chatContainer = ref.current
    const { scrollTop, scrollHeight, clientHeight } = chatContainer

    if (scrollTop < prevScrollTopRef.current) {
      setAutoScroll(false)
    }

    if (scrollTop + clientHeight >= scrollHeight) {
      setAutoScroll(true)
    }

    prevScrollTopRef.current = scrollTop
  }, [ref, setAutoScroll])

  useEffect(() => {
    if (ref.current) {
      const chatContainer = ref.current
      chatContainer.addEventListener('scroll', handleUserScroll)
      return () => {
        chatContainer.removeEventListener('scroll', handleUserScroll)
      }
    }
  }, [handleUserScroll, ref])

  useEffect(() => {
    if (autoScroll && ref.current) {
      const chatContainer = ref.current
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
  }, [dependency, autoScroll, ref])

  useEffect(() => {
    if (ref.current) {
      const chatContainer = ref.current
      chatContainer.scrollTop = 0
    }
  }, [ref])
}
