import { createContext, useState, useContext } from 'react'

const PlayingContext = createContext()

export const usePlayingContext = () => useContext(PlayingContext)

export const PlayingProvider = ({ children }) => {
  const [playingIndex, setPlayingIndex] = useState(null)
  const [isGlobalLoading, setIsGlobalLoading] = useState(false)

  return (
    <PlayingContext.Provider value={{ playingIndex, setPlayingIndex, isGlobalLoading, setIsGlobalLoading }}>
      {children}
    </PlayingContext.Provider>
  )
}
