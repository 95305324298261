const Style = {
  button: '',
  icon: 'hidden lg:inline top-0 float-right ml-2 hover:text-black/80 cursor-pointer',
  feedback: 'fixed right-2 top-5 left-2 md:left-auto w-96',
  form: 'mt-4',
  rating: 'text-center mb-4',
  ratingButton:
    'relative inline-block mr-4 w-10 h-10 cursor-pointer text-center ease-in-out duration-300 hover:scale-125 last:mr-0',
  ratingIcon: 'absolute top-1/2 left-1/2 w-full h-full -translate-x-1/2 -translate-y-1/2',
  close: 'absolute top-3 right-3 opacity-60 ease-in-out duration-300 hover:opacity-100',
}

export default Style
