/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import React, { useContext, useState, useEffect } from 'react'
import { AuthContext, Animate, Row, Card, SocialSignin, Form, Link } from 'components/lib'
import { Helmet } from 'react-helmet-async'

export function Signup(props) {
  const [referralSource, setReferralSource] = useState('')
  const context = useContext(AuthContext)
  const handleInputChange = (value) => {
    setReferralSource(value)
  }

  const initialInputs = {
    name: {
      label: 'First Name',
      type: 'text',
      required: true,
      errorMessage: 'Please enter your first name',
    },
    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
      complexPassword: true,
    },
    source: {
      label: 'How did you hear about us?',
      type: 'select',
      required: false,
      callback: handleInputChange,
      options: [
        { name: 'Word of mouth', label: 'Word of mouth' },
        { name: 'LinkedIn', label: 'LinkedIn' },
        { name: 'Facebook', label: 'Facebook' },
        { name: 'Instagram', label: 'Instagram' },
        { name: 'Other', label: 'Other' },
      ],
    },
  }

  const [inputs, setInputs] = useState(initialInputs)

  useEffect(() => {
    const newInputs = { ...initialInputs }
    if (referralSource === initialInputs.source.options[initialInputs.source.options.length - 1].name) {
      newInputs.other = {
        label: 'Other',
        type: 'text',
        required: true,
      }
      console.log(referralSource)
    }
    setInputs(newInputs)
  }, [referralSource])

  return (
    <Animate type="pop">
      <Helmet>
        <meta
          name="description"
          content="Join Law Cyborg: AI-powered tax research for Kiwi accountants on the cutting-edge. Sign up for instant access and streamline your workflow."
        />
      </Helmet>
      <Row title="Create Your Account">
        <Card loading={false} restrictWidth center>
          <Form
            inputs={{
              name: {
                label: 'First Name',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name',
              },
              email: {
                label: 'Email',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
              source: {
                label: 'How did you hear about us?',
                type: 'select',
                required: false,
                callback: handleInputChange,
                options: [
                  { name: 'Word of mouth', label: 'Word of mouth' },
                  { name: 'LinkedIn', label: 'LinkedIn' },
                  { name: 'Facebook', label: 'Facebook' },
                  { name: 'Instagram', label: 'Instagram' },
                  { name: 'Other', label: 'Other' },
                ],
              },
              ...(referralSource === 'Other' && {
                other: {
                  label: 'Other',
                  type: 'text',
                  required: true,
                },
              }),
            }}
            url="/api/account"
            method="POST"
            buttonText="Create Account"
            callback={context.signin}
          />

          <div className="mt-4">
            Already registered? <Link url="/signin" text="Sign In" />
          </div>
        </Card>
      </Row>
    </Animate>
  )
}
