import React from 'react'

const ProgressBar = ({ current, total }) => {
  const percentage = !current ? current : (current / total) * 100
  return (
    <div className="flex items-center">
      <div className="w-4/6 bg-gray-200 rounded-full h-2.5">
        <div className="bg-brand-500 h-2.5 rounded-full" style={{ width: `${percentage}%` }}></div>
      </div>
      <span className="ml-3 text-gray-600">
        {current}/{total}
      </span>
    </div>
  )
}

export default ProgressBar
