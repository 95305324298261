const NewIcon = ({ width = 17, height = 17, fill = '#475569', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M14.722 1.242a14.153 14.153 0 0 0-.647-.623 2.438 2.438 0 0 0-.67-.438 2.383 2.383 0 0 0-1.823 0 2.437 2.437 0 0 0-.67.438c-.188.164-.4.376-.647.623L5.51 5.996c-.33.329-.58.578-.767.874-.16.25-.283.522-.367.807-.1.337-.123.688-.154 1.153l-.105 1.544a4.169 4.169 0 0 0-.014.347c.003.126.018.314.114.51.117.24.31.434.55.551.197.096.385.111.511.114.111.002.238-.007.347-.014l1.544-.105c.465-.031.816-.055 1.153-.154.285-.084.556-.207.807-.367.296-.188.545-.437.874-.767l4.754-4.754c.247-.246.46-.459.623-.647a2.44 2.44 0 0 0 .438-.67 2.384 2.384 0 0 0 0-1.824 2.44 2.44 0 0 0-.438-.67c-.164-.187-.376-.4-.623-.646l-.036-.036Zm-1.925.407a.923.923 0 0 1 .234.167c.142.124.316.298.586.567.27.27.443.444.567.586.12.137.153.2.167.233.08.195.08.414 0 .608a.92.92 0 0 1-.167.234 8.178 8.178 0 0 1-.343.36L11.595 2.16a8.22 8.22 0 0 1 .36-.343.92.92 0 0 1 .235-.167.794.794 0 0 1 .607 0Zm-2.326 1.633 2.247 2.247-3.784 3.784c-.405.405-.526.52-.657.603-.125.08-.26.141-.403.183-.148.044-.314.06-.886.098l-1.272.087.087-1.272c.038-.572.054-.738.098-.886.042-.142.103-.278.183-.403.083-.13.198-.252.603-.657l3.784-3.784Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      d="M7.154.136c-1.101 0-1.977 0-2.683.058-.723.06-1.34.186-1.902.478A4.766 4.766 0 0 0 .537 2.705C.244 3.268.119 3.884.059 4.607 0 5.313 0 6.188 0 7.289v1.58c0 1.101 0 1.977.059 2.683.06.723.185 1.34.478 1.902a4.767 4.767 0 0 0 2.032 2.032c.563.293 1.179.418 1.902.478.706.059 1.582.059 2.682.059h1.62c1.082 0 1.944 0 2.638-.057.712-.058 1.318-.18 1.874-.463a4.766 4.766 0 0 0 2.083-2.082c.283-.556.404-1.163.462-1.874.057-.695.057-1.556.057-2.638v-.035a.794.794 0 1 0-1.589 0c0 1.125 0 1.921-.051 2.544-.05.613-.145.987-.295 1.281a3.178 3.178 0 0 1-1.389 1.389c-.294.15-.668.245-1.281.295-.623.05-1.419.051-2.544.051h-1.55c-1.143 0-1.953 0-2.586-.053-.623-.052-1.002-.15-1.3-.305a3.178 3.178 0 0 1-1.356-1.355c-.154-.298-.252-.678-.304-1.3-.053-.633-.053-1.443-.053-2.587v-1.51c0-1.144 0-1.953.053-2.586.052-.623.15-1.003.304-1.3a3.177 3.177 0 0 1 1.355-1.356c.299-.155.678-.253 1.301-.304.633-.053 1.443-.054 2.587-.054h.755a.794.794 0 1 0 0-1.588h-.79Z"
    />
  </svg>
)

export { NewIcon }
