/***
 *
 *   LOADER
 *   Infinite spinning animation for loading states
 *
 **********/

// import Orbit from './images/orbit.svg';
// import lawCyborgLoader from './images/loader.png';
import LawCyborgLoader from './animatedloader.js'
import { ClassHelper } from 'components/lib'
import Style from './loader.tailwind.js'

export function Loader(props) {
  const loaderStyle = ClassHelper(Style, props)

  return (
    // <div className={ loaderStyle }>
    //   <img src={ lawCyborgLoader } className={ Style.orbit } alt='Law Cyborg Logo Animation'/>
    // </div>

    <div className={loaderStyle}>
      <LawCyborgLoader />
    </div>
  )
}
