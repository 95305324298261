const Style = {
  multiSelectBase: 'flex items-center gap-1 px-2 py-1 rounded-lg m-1 transition ease-in-out delay-150 duration-300',
  variants: {
    default: 'border bg-[#4755690D]',
    secondary: 'border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80',
    destructive: 'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
    inverted: 'inverted',
  },
  button:
    'flex w-full p-[5px] block rounded-xl border min-h-10 h-[50px] items-center justify-between bg-inherit hover:bg-inherit',
  popoverContent: 'border rounded-lg z-10 bg-white p-4 w-[var(--radix-popper-anchor-width)]',
  optionButton: 'flex items-center px-2 py-1 mb-[1px] text-sm w-full text-left rounded hover:bg-gray-100',
  toggleAllButton: 'flex items-center px-2 py-1 text-sm text-left w-full hover:bg-gray-100 rounded',
  itemBase: 'flex items-center justify-between',
  noItems: 'px-2 py-1 text-sm text-gray-500',
  placeholder: 'text-base text-[#9CA3AF] font-normal  mx-3',
  additionalItems: 'flex flex-wrap items-center text-sm',
  transition: 'mr-2 transition-all duration-300',
}

export default Style
