/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import { useState, useEffect, createContext, useCallback } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { moduleIds } from '@lawcyborg/packages'
import { useAccess } from 'hooks'

// auth context
export const AuthContext = createContext()

const useAPI = require('components/lib').useAPI
const Event = require('components/lib').Event
const permissions = require('./permissions')

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem('user'))
  const [user, setUser] = useState(cache)
  const initAuthData = useAPI(user ? '/api/auth' : null)
  const [authData, setAuthData] = useState()

  useEffect(() => {
    // update the auth status
    if (!initAuthData.loading && initAuthData.data) {
      initAuthData.data.authenticated ? update(initAuthData.data) : signout()
    }
    if (initAuthData.error?.response?.status === 401) {
      signout()
    }
  }, [initAuthData])

  useEffect(() => {
    // update the auth status
    if (!authData) return
    if (!authData.loading && authData.data) {
      authData.data.authenticated ? update(authData.data) : signout()
    }
    if (authData.error?.response?.status === 401) {
      signout()
    }
  }, [authData])

  function signin(res) {
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data))
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
      Event('signin')

      if (!res.data.verified) return (window.location = '/signup/verify')

      if (!res.data.plan) return (window.location = '/signup/plan')

      // return window.location = '/';
      return (window.location = res.data.onboarded ? '/' : '/welcome')
    }
  }

  async function signout() {
    axios({ method: 'delete', url: '/api/auth' })
    localStorage.clear()
    return (window.location = '/signin')
  }

  async function switchAccount(id) {
    const res = await axios({
      method: 'post',
      url: '/api/auth/switch',
      data: { account: id },
    })

    if (res.data) signin(res)
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key]
        } else if (typeof data[key] === 'object') {
          if (!user[key] || typeof user[key] !== 'object') {
            user[key] = {}
          }

          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey]
          }
        } else {
          user[key] = data[key]
        }
      }

      localStorage.setItem('user', JSON.stringify(user))
      setUser(user)
    }
  }

  const refreshAuth = useCallback(() => {
    axios({
      url: '/api/auth',
      method: 'get',
      data: {},
    }).then((res) => {
      setAuthData(res.data)
    })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user: user,
        signin: signin,
        signout: signout,
        update: update,
        switchAccount: switchAccount,
        refreshAuth,
        permission: permissions[user?.permission],
      }}
      {...props}
    />
  )
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  // check user exists
  const user = JSON.parse(localStorage.getItem('user'))
  const availableLicensesSet = new Set(user?.userLicenses?.map((license) => license.module_id))
  const hasAccess = useAccess()

  const path = window.location.pathname
  const permittedRoutes = ['/account/billing', '/signup/plan', '/account/upgrade', '/account', '/account/profile']

  if (user?.token) {
    if (permissions[user.permission][props.permission]) {
      if (user.verified) {
        // user has no plan
        if (!user.plan && path !== '/account/profile' && path !== '/signup/plan') return <Navigate to="/signup/plan" />

        // user has no subscription
        if (
          user.subscription !== 'active' &&
          user.subscription !== 'trialing' &&
          user.permission !== 'master' &&
          !permittedRoutes.includes(path)
        )
          return <Navigate to="/account/billing" />

        // User doesn't have feature flag for page
        if (props.featureFlag && !user.feature_flags[props.featureFlag]) return <Navigate to="/" />
        // If user doesn't have License
        // FIXME: CREATE A PAGE THAT SAY'S: YOU DON'T HAVE ENOUGH LICENSE TO GET THE PAGE
        if (props.license && !hasAccess(props.license)) {
          if (!hasAccess(moduleIds.ESSENTIAL_PLAN)) return <Navigate to="/account/profile" />
          else return <Navigate to="/" />
        }
        // hide plan view if user is on plan
        if (user.plan && path === '/signup/plan') return <Navigate to="/" />
        // hide verified view if user is verified
        if (path === '/signup/verify') return <Navigate to="/" />
      } else {
        // user is not verified
        if (path !== '/account/profile' && path !== '/signup/verify') return <Navigate to="/signup/verify" />
      }

      // user is good
      return props.children
    }
  }

  // user is not authenticated
  return <Navigate to="/signin" />
}
