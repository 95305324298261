import { useState } from 'react'
import { cn } from 'utils'

export const InlineEditableField = ({ value, onSave, placeholder, className, inputClassName }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value)

  const handleSave = () => {
    onSave(editedValue)
    setIsEditing(false)
  }

  const handleCancel = () => {
    setEditedValue(value)
    setIsEditing(false)
  }

  return (
    <div
      className={cn(className, 'relative', !isEditing ? 'cursor-pointer hover:bg-gray-100' : '')}
      onClick={() => !isEditing && setIsEditing(true)}
    >
      {isEditing ? (
        <input
          className={cn('border-b-2 focus:outline-none w-full', inputClassName)}
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          onBlur={handleSave}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSave()
            if (e.key === 'Escape') handleCancel()
          }}
          autoFocus
        />
      ) : (
        <p className="border-b-2 border-transparent">{value || placeholder}</p>
      )}
    </div>
  )
}
