const GeneralAiIcon = ({ width = 19, height = 19, fill = '#fff', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 19 19" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M10.861 10.831H8.703a.252.252 0 01-.252-.252V7.836c0-.14.113-.252.252-.252h2.158a.757.757 0 000-1.513H8.703c-.974 0-1.765.79-1.765 1.765v2.743c0 .975.791 1.765 1.765 1.765h2.158a.757.757 0 000-1.513z"
      clipRule="evenodd"
    ></path>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M10.408 10.297v1.432a.757.757 0 001.512 0v-1.432a.757.757 0 00-1.512 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M15.914 5.073a2.773 2.773 0 00-2.773-2.774H5.073a2.773 2.773 0 00-2.774 2.774v8.068a2.773 2.773 0 002.774 2.773h8.068a2.773 2.773 0 002.773-2.773V5.073zm-1.513 0v8.068c0 .695-.565 1.26-1.26 1.26H5.073c-.696 0-1.26-.565-1.26-1.26V5.073c0-.696.564-1.261 1.26-1.261h8.068c.695 0 1.26.565 1.26 1.26z"
      clipRule="evenodd"
    ></path>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M15.722 7.806h2.522a.757.757 0 000-1.513h-2.522a.757.757 0 000 1.513zM7.927 3.278V.756a.757.757 0 00-1.513 0v2.522a.757.757 0 001.513 0zM6.192 15.158v2.52a.757.757 0 001.513 0v-2.52a.757.757 0 00-1.513 0zM3.278 6.414H.756a.757.757 0 000 1.513h2.522a.757.757 0 000-1.513zM15.722 12.122h2.522a.757.757 0 000-1.513h-2.522a.757.757 0 000 1.513zM12.243 3.278V.756a.757.757 0 00-1.513 0v2.522a.757.757 0 001.513 0zM10.73 15.38v2.52a.757.757 0 001.513 0v-2.52a.757.757 0 00-1.513 0zM3.278 10.73H.756a.757.757 0 000 1.513h2.522a.757.757 0 000-1.513z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export { GeneralAiIcon }
