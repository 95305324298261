import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { Button, Error, Label } from 'components/lib'
import { DownIcon } from 'icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { cn } from 'utils'
import Style from './multiselect.tailwind'

const multiSelectVariants = ({ variant = 'default' } = {}) =>
  `${Style.multiSelectBase} ${Style.variants[variant] || Style.variants.default}`

const MultiSelect = React.forwardRef(
  (
    {
      options = [],
      selectedValues,
      onValueChange = () => {},
      variant = 'default',
      placeholder = 'Select options',
      maxCount = 3,
      modalPopover = false,
      asChild = false,
      singleSelect = false,
      className = '',
      ...props
    },
    ref
  ) => {
    const isControlled = selectedValues !== undefined && onValueChange !== undefined
    const [internalSelectedValues, setInternalSelectedValues] = useState(selectedValues || [])
    const currentSelectedValues = isControlled ? selectedValues : internalSelectedValues
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const popoverRef = useRef(null)
    const dropdownRotate = isPopoverOpen ? 'rotate-180' : 'rotate-0'
    let error = props.errorMessage || 'Please select an option'

    useEffect(() => {
      const validSelectedValues = currentSelectedValues.filter((value) =>
        options.some((option) => option.value === value)
      )
      if (validSelectedValues.length !== currentSelectedValues.length) {
        handleValueChange(validSelectedValues)
      }
    }, [options])

    const handleValueChange = (newValues) => {
      if (isControlled) {
        onValueChange(newValues)
      } else {
        setInternalSelectedValues(newValues)
        onValueChange && onValueChange(newValues)
      }
    }

    const toggleOption = (option) => {
      const newSelectedValues = singleSelect
        ? [option] // Only allow one selection if singleSelect is true
        : currentSelectedValues.includes(option)
          ? currentSelectedValues.filter((value) => value !== option)
          : [...currentSelectedValues, option]
      handleValueChange(newSelectedValues)

      if (singleSelect) {
        setIsPopoverOpen(false)
      }
    }

    const handleClear = () => {
      handleValueChange([])
    }

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev)
    }

    const toggleAll = () => {
      if (!singleSelect) {
        // Only allow "Select All" in multi-select mode
        if (currentSelectedValues.length === options.length) {
          handleClear()
        } else {
          const allValues = options.map((option) => option.value)
          handleValueChange(allValues)
        }
      }
    }

    const handlePopoverKeyDown = useCallback(
      (event) => {
        if (event.key === 'Escape') {
          setIsPopoverOpen(false)
        }
      },
      [setIsPopoverOpen]
    )

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={(isOpen) => {
          setIsPopoverOpen(isOpen)
          if (!isOpen) onValueChange(currentSelectedValues)
        }}
        modal={modalPopover}
        ref={popoverRef}
      >
        <div className={cn(className, 'mb-4')}>
          {props.label && <Label text={props.label} required={props.required} for={props.name} />}
          <PopoverTrigger asChild>
            <button
              ref={ref}
              {...props}
              onClick={handleTogglePopover}
              className={cn(Style.button)}
              aria-haspopup="listbox"
              aria-expanded={isPopoverOpen}
              aria-labelledby="multiselect-label"
            >
              {currentSelectedValues.length > 0 ? (
                <div
                  className={cn(Style.itemBase, 'w-full')}
                  role="button"
                  aria-label={`${currentSelectedValues.length} selected`}
                >
                  <div className={Style.additionalItems}>
                    {currentSelectedValues.slice(0, maxCount).map((value) => {
                      const option = options.find((o) => o.value === value)
                      if (!option) return null
                      return (
                        <div
                          key={value}
                          className={cn(
                            multiSelectVariants({ variant }),
                            singleSelect ? 'border-none bg-transparent' : ''
                          )}
                        >
                          {option.label}
                          {!singleSelect && ( // Show clear button only in multi-select mode
                            <Button icon="x" size={16} action={() => toggleOption(value)} />
                          )}
                        </div>
                      )
                    })}
                    {currentSelectedValues.length > maxCount && (
                      <div className={cn('', multiSelectVariants({ variant }))}>
                        {`+ ${currentSelectedValues.length - maxCount} more`}
                        <Button size={16} icon="x" action={handleClear} />
                      </div>
                    )}
                  </div>
                  <div className={Style.itemBase}>
                    <Button icon="x" className="mr-2" action={handleClear} />
                    <DownIcon className={cn(dropdownRotate, Style.transition)} />
                  </div>
                </div>
              ) : (
                <div className={cn(Style.itemBase, 'w-full mx-auto')} id="multiselect-label">
                  <span className={Style.placeholder}>{placeholder}</span>
                  <DownIcon className={cn(dropdownRotate, Style.transition)} />
                </div>
              )}
            </button>
          </PopoverTrigger>
          {props.valid === false && <Error message={error} />}
        </div>
        <PopoverContent className={Style.popoverContent} align="start" onKeyDown={handlePopoverKeyDown}>
          <div className="flex flex-col">
            {!singleSelect && (
              <div className={cn(Style.itemBase, 'mb-2')}>
                <button
                  type="button"
                  onClick={toggleAll}
                  className={Style.toggleAllButton}
                  aria-pressed={currentSelectedValues?.length === options?.length}
                >
                  <span>Select All</span>
                </button>
              </div>
            )}
            <div className="max-h-60 overflow-y-auto">
              {options.length > 0 ? (
                options.map((option) => {
                  const isSelected = currentSelectedValues.includes(option.value)
                  return (
                    <button
                      key={option.value}
                      type="button"
                      onClick={() => toggleOption(option.value)}
                      className={cn(Style.optionButton, isSelected && 'bg-gray-200')}
                      aria-pressed={isSelected}
                    >
                      <span>{option.label}</span>{' '}
                      {option?.badge && (
                        <span className="leading-snug ml-2 bg-gray-50 text-[9px] font-semibold text-gray-600 py-1 px-2 uppercase rounded-lg shadow-sm border border-gray-300 tracking-wide">
                          {option.badge}
                        </span>
                      )}
                    </button>
                  )
                })
              ) : (
                <div className={Style.noItems}>No options available.</div>
              )}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    )
  }
)

MultiSelect.displayName = 'MultiSelect'
export default MultiSelect
