import Mermaid from 'components/mermaid/Mermaid'
import React from 'react'
import { useEffect } from 'react'
import DownloadablePartWrapper from './DownloadablePartWrapper'
import { useRef } from 'react'

const MermaidPart = React.memo(({ message, setCopyText, index }) => {
  useEffect(() => {
    setCopyText('[DIAGRAM]', index)
  }, [message, index, setCopyText])
  const mermaidRef = useRef(null)
  return (
    <DownloadablePartWrapper
      onClick={(e) => {
        const svgElement = mermaidRef.current.querySelector('svg')
        if (!svgElement) return

        const serializer = new XMLSerializer()
        const svgString = serializer.serializeToString(svgElement)

        const blob = new Blob([svgString], { type: 'image/svg+xml' })
        const url = URL.createObjectURL(blob)

        const downloadLink = document.createElement('a')
        downloadLink.href = url
        downloadLink.download = 'diagram.svg'
        downloadLink.click()

        URL.revokeObjectURL(url) // Clean up
      }}
      type="diagram"
    >
      <Mermaid chart={message} ref={mermaidRef} />
    </DownloadablePartWrapper>
  )
})

export default MermaidPart
