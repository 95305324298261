import { useEffect, useState } from 'react'
import FilterOption from './filterOption'
import { DownIcon } from 'icons'

/**
 * FilterCategory Component
 *
 * A collapsible component for managing and displaying a nested set of filter options.
 * Each filter is represented as a checkbox and can have nested options.
 *
 * The component manages its internal state for expanded/collapsed views and
 * for nested filter states, notifying the parent component of any filter changes.
 * This means that the parent component should provide the initial filter state and
 * subsequent rerenders with a changed filters prop will not be reflected in the component.
 *
 * @param {Object} props - The properties object.
 * @param {string} name - The title or label for the filter category. When provided,
 *                              clicking it toggles the collapse state of the filter section.
 * @param {Object} filters - A nested object defining the filter structure. Each filter option can
 *                           contain child options or be a leaf object. Leaf objects should have:
 *                               - `checked`: (boolean) - Indicates if the checkbox is selected.
 *                               - `value`: (any) - The actual value of the checkbox.
 * @param {function} onFilterChange - A callback function that is triggered whenever the filters'
 *                                    state changes. It receives the current `filters` state as an argument.
 * @param {boolean} showName - A boolean flag to show or hide the category name.
 *
 * @example
 * // Example filters structure for a FilterCategory
 * const filters = {
 *   option1: { checked: true, value: 'option 1' },
 *   option2: {
 *     suboption1: { checked: false, value: 'suboption 1' },
 *     suboption1: { checked: true, value: 'suboption 2' }
 *   }
 * };
 *
 * <FilterCategory
 *   name="Example Category"
 *   filters={filters}
 *   onFilterChange={(updatedFilters) => console.log(updatedFilters)}
 * />
 *
 */

const FilterCategory = ({ name, filters: filterParam, onFilterChange, showName }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [filters, setFilters] = useState(filterParam)

  const updateNestedState = (state, path, value) => {
    if (path.length === 1) {
      return { ...state, [path[0]]: value }
    } else {
      return {
        ...state,
        [path[0]]: updateNestedState(state[path[0]] || {}, path.slice(1), value),
      }
    }
  }

  const handleNamespaceChange = (optionPath, isChecked) => {
    setFilters((prevFilters) => updateNestedState(prevFilters, [...optionPath], isChecked))
  }

  useEffect(() => {
    if (onFilterChange) onFilterChange(filters, name)
  }, [filters, onFilterChange, name])

  return (
    <div className="py-2">
      {name && showName && (
        <h3
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="uppercase relative font-semibold text-[12px] text-primary-dark"
        >
          {name}
          <div
            onClick={() => setIsCollapsed(!isCollapsed)}
            data-expand="false"
            className={`expandIcon absolute right-0 top-0 bottom-0 flex items-center justify-center w-6 h-full cursor-pointer ${isCollapsed ? '' : 'rotate-180'}`}
          >
            <DownIcon className="transition-transform duration-300" />
          </div>
        </h3>
      )}
      {(!name || !isCollapsed || (name && !showName)) && (
        <div className="mt-2 mb-[-0.5rem]">
          {Object.keys(filters).map((option, index) => (
            <FilterOption
              key={option}
              option={option}
              value={filters[option]}
              onFilterChange={(optionPath, isChecked) => {
                handleNamespaceChange([option, ...optionPath], isChecked)
              }}
              isFirstLayerOption={index !== 0}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default FilterCategory
