const NavToggle = ({ width = 12, height = 11, fill = '#fff', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={11} viewBox="0 0 12 11" fill="none" {...props}>
    <path
      fill="#475569"
      d="M12 5.475a.825.825 0 0 1-.217.56l-4.498 4.733a.734.734 0 0 1-.532.232.734.734 0 0 1-.533-.232.813.813 0 0 1-.22-.56c0-.21.08-.412.22-.56l3.973-4.173-3.965-4.173A.815.815 0 0 1 6.268.23a.733.733 0 0 1 .5-.23.727.727 0 0 1 .517.189l4.498 4.733a.81.81 0 0 1 .217.552Z"
    />
    <path
      fill="#475569"
      d="M6 5.475a.825.825 0 0 1-.217.56l-4.498 4.733A.734.734 0 0 1 .753 11a.734.734 0 0 1-.533-.232.814.814 0 0 1-.22-.56c0-.21.08-.412.22-.56l3.973-4.173L.228 1.302A.815.815 0 0 1 .268.23a.733.733 0 0 1 .5-.23.727.727 0 0 1 .517.189l4.498 4.733A.81.81 0 0 1 6 5.475Z"
      opacity={0.4}
    />
  </svg>
)

export { NavToggle }
