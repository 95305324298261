const HistoryIcon = ({ width = 33, height = 33, fill = '#475569', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 33 33" fill="none" {...props}>
    <g fill={fill} clipPath="url(#a)">
      <path d="M15.75 3.125c3.428 0 4.258.1 5.514.25.334.04.71.086 1.165.133l.052.005c.335.036.665.108.984.216l.065.022.049.018h.008l.008.003a4.602 4.602 0 0 1 2.9 3.27l.061.58c.155 1.499.28 3.758.343 6.385a1.518 1.518 0 0 0 1.521 1.482 1.614 1.614 0 0 0 1.608-1.629c-.03-3.996-.357-6.556-.357-6.556a7.7 7.7 0 0 0-5.22-6.511 7.694 7.694 0 0 0-1.649-.36L22.75.429c-2.142-.22-2.485-.396-7-.396-4.51 0-5.241.176-7.382.399l-.053.005a7.682 7.682 0 0 0-1.728.387v-.03a7.7 7.7 0 0 0-5.18 6.497C1.136 9.868 1 12.354 1 16.342c0 3.988.137 6.474.405 9.053a7.709 7.709 0 0 0 6.87 6.869l.053.005c1.865.194 2.366.353 5.45.39a1.547 1.547 0 0 0 .05-3.092c-2.007-.041-3.222-.149-4.018-.244-.333-.04-.709-.085-1.164-.132l-.051-.006a4.614 4.614 0 0 1-4.113-4.113c-.233-2.236-.387-4.576-.387-8.732s.156-6.495.387-8.731A4.6 4.6 0 0 1 7.09 3.923l.51-.178c.334-.116.68-.194 1.032-.232l.055-.005c.348-.037.653-.07.948-.104 1.494-.168 2.482-.279 6.114-.279" />
      <path d="M23.548 33.032c-4.66 0-8.452-3.791-8.452-8.451a8.46 8.46 0 0 1 8.452-8.452c4.66 0 8.452 3.79 8.452 8.451 0 4.661-3.792 8.452-8.452 8.452Zm0-13.81a5.357 5.357 0 1 0 0 10.715 5.357 5.357 0 0 0 0-10.715ZM8.484 8.614h14.068a1.536 1.536 0 0 1 0 3.07H8.484a1.536 1.536 0 0 1 0-3.07Z" />
      <path d="M8.484 18.254a1.535 1.535 0 0 1 0-3.07h8.022a1.535 1.535 0 0 1 0 3.07H8.484ZM26.285 26.168H23.41a1.403 1.403 0 0 1-1.402-1.402v-3.448a1.402 1.402 0 0 1 2.803 0v2.046h1.473a1.402 1.402 0 0 1 0 2.804Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h33v33H0z" />
      </clipPath>
    </defs>
  </svg>
)

export { HistoryIcon }
