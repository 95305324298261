const TaxAiIcon = ({ width = 19, height = 19, fill = '#fff', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 19 19" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M8.904 7.446v4.108a.77.77 0 001.541 0V7.446a.77.77 0 00-1.54 0z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M16.432 5.392a2.824 2.824 0 00-2.824-2.824H5.392a2.824 2.824 0 00-2.824 2.824v8.216a2.824 2.824 0 002.824 2.824h8.216a2.824 2.824 0 002.824-2.824V5.392zm-1.54 0v8.216c0 .709-.575 1.284-1.284 1.284H5.392a1.284 1.284 0 01-1.284-1.284V5.392c0-.709.575-1.284 1.284-1.284h8.216c.709 0 1.284.575 1.284 1.284z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M11.143 6.409h-3.08a.77.77 0 000 1.54h3.08a.77.77 0 000-1.54zM15.662 8.072h2.568a.77.77 0 000-1.54h-2.568a.77.77 0 000 1.54zM8.072 3.338V.77a.77.77 0 00-1.54 0v2.568a.77.77 0 001.54 0zM6.532 15.662v2.568a.77.77 0 001.54 0v-2.568a.77.77 0 00-1.54 0zM3.338 6.532H.77a.77.77 0 000 1.54h2.568a.77.77 0 000-1.54zM15.662 12.468h2.568a.77.77 0 000-1.54h-2.568a.77.77 0 000 1.54zM12.468 3.338V.77a.77.77 0 00-1.54 0v2.568a.77.77 0 001.54 0zM10.928 15.662v2.568a.77.77 0 001.54 0v-2.568a.77.77 0 00-1.54 0zM3.338 10.928H.77a.77.77 0 000 1.54h2.568a.77.77 0 000-1.54z"
      clipRule="evenodd"
    />
  </svg>
)

export { TaxAiIcon }
