const Style = {
  tabs: 'p-3 flex gap-16 text-sm border-b-2',
  button: `font-medium relative`,
  buttonActive: 'font-semibold text-primary-dark-blue',
  buttonActiveBar:
    'absolute -bottom-3.5 rounded left-0 w-full h-0.5 bg-primary-dark-blue transform transition-all duration-300 ease-in-out',
  inActiveBar: 'scale-x-0',
  activeBar: 'scale-x-100',
}

export default Style
