import { createContext, useContext, useState, useEffect } from 'react'
import { textToVoice } from './textToVoice'
import { ViewContext } from 'components/lib'
import { getGlobalAudio } from './globalAudio'
import { usePlayingContext } from './usePlayingContext'

const AudioContext = createContext()

export const useAudio = () => useContext(AudioContext)

export const AudioProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isAudioLoading, setIsLoading] = useState(false)
  const [audioBlob, setAudioBlob] = useState(null)
  const [audioIndex, setAudioIndex] = useState(null)
  const viewContext = useContext(ViewContext)
  const globalAudio = getGlobalAudio()
  const { setIsGlobalLoading, setPlayingIndex } = usePlayingContext()

  const handleSynthesizeAndPlayAudio = async (response, audioKey) => {
    try {
      setIsLoading(true)
      setIsGlobalLoading(true)
      const blob = await textToVoice(response)
      if (!blob) {
        throw new Error('Synthesis returned no audio blob.')
      }
      setAudioBlob(blob)
      setAudioIndex(audioKey)
      playAudio(blob)
      setIsLoading(false)
      setIsGlobalLoading(false)
    } catch (error) {
      viewContext.notification.show('Sorry, there is an error occured when playing the audio.', 'error', false)
      setIsLoading(false)
      setIsPlaying(false)
      setAudioBlob(null)
      setAudioIndex(null)
      setIsGlobalLoading(false)
      setPlayingIndex(null)
    }
  }

  const playAudio = (blob) => {
    const url = URL.createObjectURL(blob)
    globalAudio.src = url
    globalAudio
      .play()
      .then(() => {
        setIsPlaying(true)
      })
      .catch((error) => {
        console.warn('Audio play error:', error)
        setIsPlaying(false)
      })

    globalAudio.onended = () => {
      URL.revokeObjectURL(url)
      setIsPlaying(false)
      setPlayingIndex(null)
    }
  }

  const togglePlay = (audioKey, externalPause = false) => {
    if (isPlaying && !externalPause) {
      globalAudio.pause()
      setIsPlaying(false)
    } else if (audioBlob && audioKey === audioIndex) {
      globalAudio.pause()
      setIsPlaying(false)
      playAudio(audioBlob)
    }
  }

  useEffect(() => {
    return () => {
      if (globalAudio) {
        globalAudio.pause()
        setIsPlaying(false)
      }
    }
  }, [globalAudio])

  return (
    <AudioContext.Provider value={{ isAudioLoading, audioBlob, audioIndex, handleSynthesizeAndPlayAudio, togglePlay }}>
      {children}
    </AudioContext.Provider>
  )
}
