const ProfileIcon = ({ width = 96, height = 97, fill = '#12367F', fill1 = '#27D1FF', fill2 = '#1974F9', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 96 97" {...props}>
    <circle cx={48} cy={48} r={48} fill="#F6F6F7" />
    <path
      fill={fill}
      d="M47.708 51.891c9.635 0 17.445-7.81 17.445-17.445C65.153 24.81 57.343 17 47.707 17c-9.635 0-17.445 7.81-17.445 17.446 0 9.635 7.81 17.445 17.446 17.445Z"
    />
    <path
      fill={fill1}
      fillRule="evenodd"
      d="M15.025 81.18a2.095 2.095 0 0 0 .613 1.83c8.284 8.065 19.603 13.034 32.071 13.034 12.468 0 23.787-4.969 32.071-13.035a2.095 2.095 0 0 0 .613-1.83 27.401 27.401 0 0 0-27.069-23.166h-11.23A27.401 27.401 0 0 0 15.025 81.18Z"
      clipRule="evenodd"
    />
    <path
      fill={fill2}
      fillRule="evenodd"
      d="M16.502 75.633a27.39 27.39 0 0 0-1.477 5.548 2.095 2.095 0 0 0 .613 1.83c8.284 8.066 19.603 13.035 32.071 13.035 12.468 0 23.787-4.97 32.071-13.035a2.095 2.095 0 0 0 .613-1.83 27.383 27.383 0 0 0-1.477-5.548c-8.2 7.583-19.167 12.216-31.207 12.216s-23.006-4.633-31.207-12.216Z"
      clipRule="evenodd"
    />
  </svg>
)

export { ProfileIcon }
