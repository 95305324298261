import React from 'react'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../sheet/sheet'
import { CrossIcon } from 'icons'

export const Drawer = ({ triggerButton, title, children, open, onOpenChange, width = '28rem' }) => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      {triggerButton ? <SheetTrigger asChild>{triggerButton}</SheetTrigger> : null}
      <SheetContent className={`bg-white w-[${width}] z-[100]`}>
        <SheetHeader className="border-b p-4 border-gray-200">
          <SheetTitle className="flex w-full justify-between items-center">
            <h3 className="text-primary-dark-blue text-left font-bold text-base">{title}</h3>
            <SheetClose className="text-gray-400 hover:text-primary-dark-blue">
              <span className="sr-only">Close</span>
              <CrossIcon />
            </SheetClose>
          </SheetTitle>
        </SheetHeader>

        <SheetDescription className="z-10 h-full p-4 overflow-auto">{children}</SheetDescription>
      </SheetContent>
    </Sheet>
  )
}
