import { AuthContext, Button, ViewContext } from 'components/lib'
import { useContext, useEffect } from 'react'
import { WarningIcon } from 'icons'
import { PWC_PILOT_ACCOUNT_ID } from 'utils'

const SplashContent = ({ action }) => {
  return (
    <div className="flex flex-col items-center ">
      <div className="bg-[linear-gradient(360deg,_#ffffff_0%,_#f6d1d1_108.02%)] h-40 absolute top-0 left-0 rounded-3xl w-full mb-3 "></div>
      <WarningIcon fill="#D10028" className="z-[1]" height={55} width={55} />

      <h2 className="z-[1] mt-9 text-primary-dark font-semibold text-2xl mb-3 flex items-center gap-1">Reminder</h2>
      <p className="z-[1] mt-4 text-center ">Law Cyborg is a research tool only.</p>
      <p className="text-center mt-1 font-medium ">
        Do not enter PwC data, client names or data or personal information.
      </p>
      <p className="text-center">
        For more information on safe use see our{' '}
        <a className="underline" href="https://sites.google.com/pwc.com/pwc-nz-gen-ai-hub/what-you-need-to-know">
          PwC NZ Generative AI Hub - business rules
        </a>
        .
      </p>
      <br />
      <Button className="!w-full" text="I Agree" action={action} color={'brand3'} />
    </div>
  )
}

const SplashPage = () => {
  const viewContext = useContext(ViewContext)
  const authContext = useContext(AuthContext)
  const userAccountId = authContext?.user?.account_id
  const shouldDisplaySplashPage = userAccountId && userAccountId === PWC_PILOT_ACCOUNT_ID

  useEffect(() => {
    const isAgreed = sessionStorage.getItem('isAgreed')
    if (!isAgreed && shouldDisplaySplashPage) {
      displaySplashPage()
    }
  }, [])

  const onAgree = () => {
    sessionStorage.setItem('isAgreed', 'true')
    viewContext.modal.hide()
  }

  const displaySplashPage = () => {
    viewContext.modal.show(
      {
        text: <SplashContent action={onAgree} />,
        closeOnOutsideClick: false,
        className:
          'max-w-[31rem] absolute left-1/2 top-1/2 text-left transform -translate-x-1/2 -translate-y-1/2  duration-300 transition-all ease-in-out [&>section]:px-[1rem] md:[&>section]:px-[4rem] w-[90%] md:w-auto',
      },
      () => {}
    )
  }
  return null
}

export default SplashPage
