import React from 'react'
import { useEffect } from 'react'

const ExcelFormulaPart = React.memo(({ message, setCopyText, index }) => {
  useEffect(() => {
    setCopyText(message, index)
  }, [message, index, setCopyText])
  return (
    <span
      style={{
        'background-color': 'black',
        color: 'white',
        padding: '2px 12px',
        borderRadius: '2px',
        'text-wrap': 'pretty',
      }}
    >
      {message}
    </span>
  )
})

export default ExcelFormulaPart
