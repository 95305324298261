const Style = {
  container: 'p-6 overflow-y-auto max-h-full',
  header: 'header h-[5.5rem] relative',
  content: 'content max-w-screen-lg mx-auto border-t p-2',
  breadcrumbs: 'breadcrumbs text-sm',
  breadcrumbLink: 'text-primary-dark hover:underline cursor-pointer',
  tools: 'tools flex items-center gap-4',
  search: {
    wrapper: 'bg-transparent',
    button: 'hover:opacity-50',
    input:
      '!w-96 h-12 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20 focus:border-sky-500/20 focus:outline-none focus:ring-2 focus:ring-sky-500/20 !bg-transparent rounded-lg',
  },
  button:
    'inline !h-12 !w-12 rounded-lg [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20',
  title: 'text-xl font-bold',
  fullTitle: 'text-primary-dark text-left text-lg font-bold  w-[calc(100%-1.5rem)]',
}
export default Style
