import { useState, useCallback } from 'react'
import { Button, DocumentHeader, useAPI, Search } from 'components/lib'
import { Link, useParams, useLocation } from 'react-router-dom'
import HtmlContent from '../htmlContent'
import classNames from './viewDocument.tailwind'
import { downloadFile } from 'utils'

const ViewDocument = () => {
  const { source, documentType, documentId } = useParams()
  const [searchDocument, setSearchDocument] = useState('')
  const { data: documentFile } = useAPI(`/api/search/document/${source}/${documentType}/${documentId}`)
  const [contentType, setContentType] = useState(null)
  const [regexMode, setRegexMode] = useState(false)
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const fullTitle = params.get('fullTitle') || ''

  const handleContentTypeChange = useCallback((type) => {
    setContentType(type)
  }, [])

  const handleSearch = useCallback((search, regexMode) => {
    setSearchDocument(search)
    setRegexMode(regexMode)
  }, [])

  return (
    <div className={classNames.container}>
      <div className={classNames.header}>
        <DocumentHeader SearchClassName={'absolute left-1/2 top-0 transform -translate-x-1/2'} />
      </div>
      <div className={classNames.content}>
        <div className="flex justify-between items-center py-4">
          <div className={classNames.breadcrumbs}>
            <Link to="/search/results">
              <span className={classNames.breadcrumbLink}>Search Result</span>
            </Link>
            {' > '} {source} / {documentType}
          </div>
          {contentType === 'text/html' && (
            <div className={classNames.tools}>
              <Search
                className={classNames.search.wrapper}
                buttonClassName={classNames.search.button}
                callback={handleSearch}
                placeholder="Search In Document..."
                inputClassName={classNames.search.input}
              />
              <Button
                icon="copy"
                color="#12367F"
                size={16}
                action={() => console.log('copied')}
                className={classNames.button}
              />
              <Button
                icon="download"
                color="#12367F"
                size={16}
                action={() => downloadFile(documentFile?.output_html_url, documentFile?.fullTitle || 'document.pdf')}
                className={classNames.button}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-4 mt-4">
          <div className={classNames.fullTitle}>{fullTitle}</div>
          <HtmlContent
            html_url={documentFile?.html_url}
            pdf_url={documentFile?.output_html_url}
            searchDocument={searchDocument}
            regexMode={regexMode}
            onContentTypeChange={handleContentTypeChange}
          />
        </div>
      </div>
    </div>
  )
}

export default ViewDocument
