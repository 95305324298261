import React, { useEffect, useCallback } from 'react'
import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'
import JSONTablePart from './messageParts/JSONTablePart'
import ChartPart from './messageParts/ChartPart'
import MermaidPart from './messageParts/MermaidPart'
import ExcelFormulaPart from './messageParts/ExcelFormulaPart'
import Animatedloader from 'components/loader/animatedloader'

function cleanJsonString(jsonString) {
  // Remove trailing commas from objects and arrays
  jsonString = jsonString.replace(/,\s*([}\]])/g, '$1')

  // Replace single quotes around keys and values with double quotes
  jsonString = jsonString.replace(/'([^']*)'/g, '"$1"')

  return jsonString
}

const ResponseBody = React.memo(({ message, setCopyText, index }) => {
  useEffect(() => {
    const cleanedCopyMessage = message
      .replace(/```\n*CHART_START.*CHART_END\n*```/gs, '[CHART HERE]')
      .replace(/```\n*TABLE_START.*TABLE_END\n*```/gs, '[TABLE HERE]')
      .replace(/```\n*MERMAID_START.*MERMAID_END\n*```/gs, '[DIAGRAM HERE]')
    setCopyText(cleanedCopyMessage, index)
  }, [message, index, setCopyText])

  // LV Required to get math working. Could not find way
  // of doing this mapping through the Markdown component
  // options prop'. Inline math should never be output by
  // ChatGPT due to our system prompt, but if it is just
  // treat it as block math
  message = message
    .replace(/\\\(\s?/g, '$$$$')
    .replace(/\s?\\\)/g, '$$$$')
    .replace(/\\\[\s*/g, '$$$$')
    .replace(/\s*\\\]/g, '$$$$')

  const code = useCallback(
    (props) => {
      const { children, className, node, ...rest } = props

      const is = (type) => {
        const trimmed = children.replace(/^\s+|\s+$/g, '')
        return (
          (trimmed.startsWith(`${type}_START`) && trimmed.endsWith(`${type}_END`)) ||
          // LV Sometimes chatgpt completely ignores the system
          // prompt and puts the type boundray on the same
          // line as the triple backticks. In these cases
          // the type will be interpreted as the code type
          // and passed as the className variable, therefore
          // do a check for this as well
          className?.endsWith(`${type}_START`)
        )
      }

      const isLoading = (type) => {
        const trimmed = children.replace(/^\s+|\s+$/g, '')
        return trimmed.startsWith(`${type}_START`) && !trimmed.endsWith(`${type}_END`)
      }

      const removeBoundary = (type) => {
        const unbounded = children.replace(`${type}_START`, '').replace(`${type}_END`, '')
        return unbounded.replace(/^\s+|\s+$/g, '')
      }

      if (!children) return null
      const supportedCodeLanguageMatch = /language-(\w+)/.exec(className || '')

      const customTypes = ['TABLE', 'CHART', 'MERMAID']
      const requiresJSONClean = ['TABLE', 'CHART']
      const loading = customTypes.some(isLoading)

      if (loading) {
        return <Animatedloader />
      }

      const customComponents = {
        TABLE: JSONTablePart,
        CHART: ChartPart,
        MERMAID: MermaidPart,
        EXCEL: ExcelFormulaPart,
      }

      const type = customTypes.find(is)
      if (type) {
        let inputMessage = removeBoundary(type)
        if (requiresJSONClean.includes(type)) {
          inputMessage = cleanJsonString(removeBoundary(type))
        }
        const Component = customComponents[type]
        return <Component message={inputMessage} setCopyText={setCopyText} index={index} />
      }

      return supportedCodeLanguageMatch ? (
        <SyntaxHighlighter
          {...rest}
          PreTag="div"
          children={String(children).replace(/\n$/, '')}
          language={supportedCodeLanguageMatch[1]}
        />
      ) : (
        <code {...rest} className={className}>
          {children}
        </code>
      )
    },
    [setCopyText, index]
  )

  const ul = useCallback(({ children, ...props }) => {
    return (
      <ul className="list-disc list-outside pl-7 mb-3" {...props}>
        {children}
      </ul>
    )
  }, [])

  const ol = useCallback(({ children, ...props }) => {
    return (
      <ol className="list-decimal  list-outside pl-7 mb-3" {...props}>
        {children}
      </ol>
    )
  }, [])

  const li = useCallback(({ children, ...props }) => {
    return (
      <li className="mb-3" {...props}>
        {children}
      </li>
    )
  }, [])

  const p = useCallback(({ children, ...props }) => {
    return (
      <p className="mb-3" {...props}>
        {children}
      </p>
    )
  }, [])

  const h = useCallback(({ children, ...props }) => {
    const Header = props.node.tagName
    return (
      <Header className="mb-3" {...props}>
        {children}
      </Header>
    )
  }, [])

  const pre = useCallback(({ children, ...props }) => {
    return (
      <pre className="mb-6" {...props}>
        {children}
      </pre>
    )
  }, [])

  return (
    <>
      <Markdown
        remarkPlugins={[[remarkMath, { singleDollarTextMath: false }], remarkGfm]}
        rehypePlugins={[rehypeKatex]}
        children={message}
        components={{
          code,
          ul,
          ol,
          p,
          h1: h,
          h2: h,
          h3: h,
          h4: h,
          h5: h,
          h6: h,
          li,
          pre,
        }}
      />
    </>
  )
})

export default ResponseBody
