import Axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { Loader, Card, Event } from 'components/lib'

export function Rnd() {
  const [messages, setMessages] = useState([])
  const [primaryActivity, setPrimaryActivity] = useState('')
  const [meetingNotes, setMeetingNotes] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const chatLogRef = useRef()

  const MAX_LENGTH_MESSAGE = 12000

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight
    }
  }, [messages])

  const handleSubmit = async (e) => {
    if (primaryActivity === '') return
    if (primaryActivity.length > MAX_LENGTH_MESSAGE) {
      setErrorMessage('too long message')
      return
    }
    setErrorMessage('') // If nothing wrong clear the error message
    e.preventDefault()

    const newMessage = {
      role: 'user',
      content: { primaryActivity, meetingNotes },
    }
    // setMessages([...messages, newMessage]);
    setPrimaryActivity('')
    setMeetingNotes('')

    setLoading(true)
    const response = await Axios({
      method: 'POST',
      url: '/api/ai/text',
      data: { messages: [...messages, newMessage] },
    })

    Event('prompt_submitted', {
      conversation_length: messages.length / 2 + 1,
    })

    setLoading(false)
    const rndKeys = Object.keys(response.data.data)
    const messageValues = rndKeys.map((key) => response.data.data[key])

    setMessages([...messages, ...messageValues])
  }

  return (
    <Card center>
      {errorMessage && <h2 className="text-red-600 text-center">error {errorMessage}</h2>}
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <label>Primary Activity:</label>
        <input
          type="text"
          name="primary_activity"
          id="primary_activity"
          style={{ width: '800px' }}
          maxLength={MAX_LENGTH_MESSAGE}
          placeholder="Input a message..."
          value={primaryActivity}
          onChange={(e) => setPrimaryActivity(e.target.value)}
          className="w-full px-3 py-2 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-sky-500 focus:ring-2 focus:ring-sky-500"
        />
        <label>Meeting Notes:</label>
        <input
          type="text"
          name="meeting_notes"
          id="meeting_notes"
          style={{ width: '800px' }}
          maxLength={MAX_LENGTH_MESSAGE}
          placeholder="Input a message..."
          value={meetingNotes}
          onChange={(e) => setMeetingNotes(e.target.value)}
          className="w-full px-3 py-2 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-sky-500 focus:ring-2 focus:ring-sky-500"
        />
        <button
          type="submit"
          className="px-4 py-2 mt-4 text-white bg-sky-500 rounded-lg shadow-md hover:bg-sky-600 focus:outline-none"
        >
          Send
        </button>
      </form>
      <div
        id="chat-log"
        ref={chatLogRef}
        className="mt-4 p-4 w-full min-h-96 max-h-196 overflow-y-auto border border-gray-300 rounded-lg shadow-sm"
      >
        {messages.map((message, index) => (
          <pre
            key={index}
            className={`message rounded-lg p-2 mb-2 ${
              message.role === 'user' ? 'bg-sky-500 text-white self-end' : 'bg-gray-200 text-gray-800 self-start'
            }`}
          >
            <div className="message__text">{message.content}</div>
          </pre>
        ))}
      </div>
      {loading && (
        <Loader id="loader" className="loader-image" src="/loader.png" alt="loading animation" draggable="false" />
      )}
    </Card>
  )
}
