import { useState, useCallback, useRef } from 'react'
import axios from 'axios'
import { useContext } from 'react'
import { ViewContext } from 'components/lib'

const useCallAPI = ({
  url: defaultURL,
  data: defaultData,
  method: defaultMethod,
  headers: defaultHeaders,
  params: defaultParams,
  initialData,
} = {}) => {
  const [data, setData] = useState(initialData || null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const context = useContext(ViewContext)
  // LV to stop infinite rerenders as handleError
  // modifies context
  const handleErrorRef = useRef(context?.handleError)

  const callAPI = useCallback(
    async ({
      url = defaultURL || '',
      method = defaultMethod || 'GET',
      requestData = defaultData || {},
      headers = defaultHeaders || {},
      params = defaultParams || {},
      onSuccess,
    } = {}) => {
      setLoading(true)
      setError(null)

      try {
        const response = await axios({
          url,
          method,
          data: requestData,
          headers,
          params,
        })
        setData(response.data)
        if (typeof onSuccess === 'function') {
          onSuccess(response.data)
        }
        return response.data
      } catch (err) {
        handleErrorRef.current && handleErrorRef.current(err.response?.data?.message || err.message)
        setError(err)
        return err
      } finally {
        setLoading(false)
      }
    },
    [defaultURL, defaultData, defaultMethod, defaultHeaders, defaultParams]
  )

  return [callAPI, data, loading, error]
}

export default useCallAPI
