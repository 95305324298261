const SearchIcon = ({ width = 19, height = 19, fill = '#12367F', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 19" fill="none" {...props}>
    <path
      fill={fill}
      d="M8.243 16.485a8.243 8.243 0 1 1 8.242-8.242 8.252 8.252 0 0 1-8.242 8.242Zm0-14.424a6.182 6.182 0 1 0 6.182 6.182A6.188 6.188 0 0 0 8.243 2.06Z"
    />
    <path
      fill={fill}
      d="M17.515 18.545a1.03 1.03 0 0 1-.728-.302l-4.121-4.12a1.03 1.03 0 0 1 1.457-1.458l4.12 4.122a1.03 1.03 0 0 1-.727 1.758Z"
    />
    <path
      fill={fill}
      d="M8.243 16.485a8.243 8.243 0 1 1 8.242-8.242 8.252 8.252 0 0 1-8.242 8.242Zm0-14.424a6.182 6.182 0 1 0 6.182 6.182A6.188 6.188 0 0 0 8.243 2.06Z"
    />
  </svg>
)

export { SearchIcon }
