import React, { useState } from 'react'
import FilterOption from './filterOption'
import { DownIcon } from 'icons'

const FilterCategory = ({ category, options, onFilterChange }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <div className="py-2">
      <h3
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="uppercase relative font-semibold text-[12px] text-primary-dark"
      >
        {category}
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          data-expand="false"
          className={`expandIcon absolute right-0 top-0 bottom-0 flex items-center justify-center w-6 h-full cursor-pointer ${isCollapsed ? '' : 'rotate-180'}`}
        >
          <DownIcon className="transition-transform duration-300" />
        </div>
      </h3>
      {!isCollapsed && (
        <div className="mt-2 mb-[-0.5rem]">
          {Object.keys(options).map((option) => (
            <FilterOption
              key={option}
              option={option}
              value={options[option]}
              onFilterChange={(optionPath, isChecked) => {
                onFilterChange([option, ...optionPath], isChecked)
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default FilterCategory
