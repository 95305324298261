import { AvatarIcon } from 'icons'
import React from 'react'

const Avatar = ({ src, alt, title = '', className }) => {
  const getInitials = (name) => {
    if (!name) return ''
    const nameParts = name.split(' ')
    const initials = nameParts.map((part) => part.charAt(0).toUpperCase()).join('')
    return initials
  }

  return (
    <div className={`${className} rounded-full overflow-hidden cursor-pointer`}>
      {src ? (
        <img src={src} alt={alt} className="w-full h-full object-cover" />
      ) : title ? (
        <span className="text-white font-bold text-xl">{getInitials(title)}</span>
      ) : (
        <AvatarIcon height={40} width={40} />
      )}
    </div>
  )
}

export default Avatar
