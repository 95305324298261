import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/animations/shift-away.css'
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/dist/tippy.css' // Core styles
import { Switch } from 'components/lib'
import FilterCategoryGroup from 'components/filterStateful/filterCategoryGroup'

const findCheckedValues = (obj) => {
  let trueKeys = []

  function recursiveSearch(currentObj) {
    for (const key in currentObj) {
      const value = currentObj[key]
      if (typeof value === 'boolean' && value === true && key === 'checked') {
        trueKeys.push(currentObj.value)
      } else if (typeof value === 'object' && value !== null) {
        recursiveSearch(value) // Recurse into nested objects
      }
    }
  }

  recursiveSearch(obj)
  return trueKeys
}

export function ChatNamespaceSelector({ filters, onSelectionChange, onAutoChange }) {
  // const [selectedNamespaces, setSelectedNamespaces] = useState([])
  const [searchParams] = useSearchParams()
  const [auto, setAuto] = useState(JSON.parse(searchParams.get('auto')) ?? true)

  const onFilterChangeHandler = useCallback(
    (filters) => {
      const selectedNamespaces = findCheckedValues(filters)
      // setSelectedNamespaces(selectedNamespaces)
      if (onSelectionChange) onSelectionChange(selectedNamespaces)
    },
    [onSelectionChange]
  )

  useEffect(() => {
    if (onAutoChange) onAutoChange(auto)
  }, [auto, onAutoChange])

  return (
    <div id="namespaceSelector" className="w-full">
      <Tippy
        content="The AI automatically considers the most important sources (recommended)"
        animation="shift-away"
        arrow={roundArrow}
        inertia={true}
      >
        <span className="font-medium ml-2 select-none text-[0.8rem]">Auto</span>
      </Tippy>
      <Switch
        default={auto}
        name="Auto"
        required={true}
        onChange={(_, checked) => {
          setAuto(checked)
        }}
      />

      {!auto && Object.keys(filters).length > 0 && (
        <FilterCategoryGroup filters={filters} onFilterChange={onFilterChangeHandler} />
      )}
    </div>
  )
}
