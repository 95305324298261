import React from 'react'
import { ConversationMessage } from 'components/lib'
import Loader from '../../components/loader/animatedloader.js'
import { ConversationResponse } from './conversationMessage/conversationResponse.js'
import { AudioProvider } from './useAudio.js'
import { PlayingProvider } from './usePlayingContext.js'

export const ConversationDisplay = React.memo(({ exchanges, loading, branch, swapBranch, canSend }) => {
  return (
    <PlayingProvider>
      <div id="chat-log" className="lg:w-[60%] md:w-[80%] mx-auto mt-14">
        {exchanges.map((exchange, index) => (
          <div key={index}>
            <ConversationMessage
              message={exchange.user.message}
              loading={loading && exchanges.length - 1 === index}
              branch={branch}
              swapBranch={swapBranch}
              canSend={canSend}
              siblingCount={exchange.siblingCount}
              siblingIndex={exchange.siblingIndex}
              id={exchange.id}
            />
            <AudioProvider>
              <ConversationResponse
                response={exchange.assistant.response}
                sources={exchange.assistant.sources}
                accuracy={exchange.assistant.accuracy}
                exchanges={exchanges}
                streaming={!canSend && exchanges.length - 1 === index}
                audioKey={exchange.id}
                responseIndex={index}
                citations={exchange.assistant.citations}
                seeAlsos={exchange.assistant.seeAlsos}
              />
            </AudioProvider>
          </div>
        ))}

        <div className="flex justify-center items-center my-10">{loading && <Loader />}</div>
      </div>
    </PlayingProvider>
  )
})
