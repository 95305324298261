import React, { useEffect, useState } from 'react'
import { formatDate, highlightText, truncateContent } from 'utils/helper'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'components/sheet/sheet'
import { Link } from 'react-router-dom'
import { CrossIcon, RedirectIcon } from 'icons'
import HtmlContent from './htmlContent'
import { useAPI } from 'components/lib'
import parse, { domToReact } from 'html-react-parser'

const options = {
  replace: ({ name, attribs, children }) => {
    if (!attribs) return
    if (name === 'b') {
      return <b style={{ backgroundColor: '#ebcfea' }}>{domToReact(children, options)}</b>
    }
  },
}

const DocumentPreview = ({ fullTitle, documentType, source, documentId }) => {
  const { data: documentFile } = useAPI(`/api/search/document/${source}/${documentType}/${documentId}`)
  return (
    <div className="max-h-full overflow-y-scroll p-5">
      <h2 className="text-lg text-primary-dark pb-2">{fullTitle}</h2>
      <HtmlContent html_url={documentFile?.html_url} pdf_url={documentFile?.output_html_url} />
    </div>
  )
}

const ResultItem = ({
  id,
  fullTitle,
  superType,
  documentType,
  last_update,
  text,
  highlight,
  dateModified,
  judgementDate,
  headline,
  namespace,
  documentId,
  summary,
  datePublished,
  source,
  origin_url,
}) => {
  const [expanded, setExpanded] = useState(false)
  const maxContentLength = 200
  const displayDate = datePublished
    ? new Date(datePublished).toLocaleDateString()
    : judgementDate
      ? new Date(judgementDate).toLocaleDateString()
      : dateModified
        ? new Date(dateModified).toLocaleDateString()
        : ''

  return (
    <div className="p-4" key={id} id="result-item">
      <Sheet>
        {source !== 'Legislation' ? (
          <SheetTrigger>
            <p className="flex items-start text-[#0C254D] text-left font-bold hover:underline text-base hover:text-[#12367F]">
              <span
                className={'flex-shrink-0 w-1 mr-2 self-stretch' + (headline ? ' bg-[#c342e0]' : ' bg-[#12367F]')}
              ></span>
              {fullTitle}
            </p>
          </SheetTrigger>
        ) : (
          <a
            className="flex items-start text-[#0C254D] text-left font-bold hover:underline text-base hover:text-[#12367F]"
            href={origin_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className={'flex-shrink-0 w-1 mr-2 self-stretch' + (headline ? ' bg-[#c342e0]' : ' bg-[#12367F]')}
            ></span>
            {fullTitle}
          </a>
        )}
        <SheetContent className="bg-white">
          <SheetHeader className=" border-b p-4 border-gray-200">
            <SheetTitle className="flex w-full justify-between items-center">
              <Link
                to={
                  source !== 'Legislation'
                    ? {
                        pathname: `/document/${source}/${documentType}/${documentId}`,
                        search: `?fullTitle=${fullTitle}`,
                      }
                    : { pathname: origin_url }
                }
                className="text-primary-dark-blue text-left font-bold  w-[calc(100%-1.5rem)] hover:underline text-base hover:text-primary-dark inline-flex items-center gap-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="truncate max-w-[90%]">{fullTitle}</p>
                <RedirectIcon fill="currentColor" width={14} height={14} />
              </Link>
              <SheetClose className="text-gray-400 hover:text-primary-dark-blue">
                <span className="sr-only">Close</span>
                <CrossIcon />
              </SheetClose>
            </SheetTitle>
          </SheetHeader>
          <SheetDescription className="h-full">
            <DocumentPreview
              fullTitle={fullTitle}
              documentType={documentType}
              source={source}
              documentId={documentId}
            />
          </SheetDescription>
        </SheetContent>

        <div className="">
          <p className="text-xs text-[#475569] py-2">
            {superType} <span className="opacity-40">{' | '}</span>
            {documentType}
            {displayDate && (
              <>
                <span className="opacity-40">{' | '}</span>
                <span className="date">{displayDate}</span>
              </>
            )}
          </p>
        </div>
        {summary && (
          <p className="text-[#0C254D] text-sm pb-2">
            {expanded ? highlightText(summary, highlight) : truncateContent(summary, maxContentLength)}
            {summary.length > maxContentLength && (
              <button onClick={() => setExpanded((prev) => !prev)} className="text-[#12367F] text-sm underline ml-2">
                {expanded ? 'Show less' : 'View more'}
              </button>
            )}
          </p>
        )}
        <p className={`text-[#6C7787] text-sm line-clamp-4  ${headline ? '' : 'bg-sky-100/35 p-1'}`}>
          {headline ? <span>{parse(headline, options)}</span> : highlightText(text, highlight)}
        </p>
      </Sheet>
    </div>
  )
}

export default ResultItem
