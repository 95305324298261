// import React from 'react'
import ViewDocument from 'views/searchAI/viewDocument'
import SearchResults from 'views/searchAI/searchResults'
import SearchTool from 'views/searchAI/searchTool'
import { moduleIds } from '@lawcyborg/packages'

const Routes = [
  {
    path: '/search',
    view: SearchTool,
    layout: 'app',
    permission: 'user',
    license: moduleIds.ELITE_PLAN,
    title: 'Search',
  },
  {
    path: '/search/results',
    view: SearchResults,
    layout: 'app',
    permission: 'user',
    license: moduleIds.ELITE_PLAN,
    title: 'Search Results',
  },
  {
    path: 'document/:source/:documentType/:documentId',
    view: ViewDocument,
    layout: 'app',
    permission: 'user',
    license: moduleIds.ELITE_PLAN,
    title: 'Document',
  },
]
export default Routes
