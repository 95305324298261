const DrFinderIcon = ({ width = 17, height = 17, fill = '#fff', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 17 17" {...props}>
    <g fill={fill} clipPath="url(#clip0_230_172)">
      <path d="M16.08 14.807V14.8H3.687a1.847 1.847 0 01-1.847-1.84V1.273a.92.92 0 00-1.84 0V12.96a3.687 3.687 0 003.687 3.687H16.08a.92.92 0 000-1.84z"></path>
      <path d="M14.968 4.24a.922.922 0 00-1.291.185l-2.14 3.066-3.023-2.275a.92.92 0 00-1.288.182l-.002.004L4.592 9.18a.92.92 0 00.713 1.476v.021a.922.922 0 00.714-.37L8.158 7.24l3.023 2.275a.92.92 0 001.291-.185l2.681-3.801a.922.922 0 00-.185-1.29z"></path>
    </g>
    <defs>
      <clipPath id="clip0_230_172">
        <path fill={fill} d="M0 0H17V17H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export { DrFinderIcon }
