const DocAiIcon = ({ width = 18, height = 15, fill = '#fff', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 18 15" {...props}>
    <path
      fill={fill}
      d="M14.813 3.947A6.483 6.483 0 0012.107.835a6.104 6.104 0 00-3.965-.77A6.226 6.226 0 004.538 1.95a6.675 6.675 0 00-1.771 3.77c-.86.215-1.614.75-2.12 1.505a3.87 3.87 0 00-.622 2.59 3.804 3.804 0 001.19 2.364c.661.61 1.515.946 2.4.944h1.803c.239 0 .468-.098.637-.274a.958.958 0 00.265-.664.958.958 0 00-.265-.663.884.884 0 00-.637-.275H3.614c-.478 0-.937-.198-1.275-.55a1.916 1.916 0 01-.528-1.327c0-.497.19-.975.528-1.327s.797-.55 1.275-.55c.24 0 .469-.098.638-.274a.958.958 0 00.264-.664A4.806 4.806 0 015.59 3.527a4.47 4.47 0 012.7-1.59 4.362 4.362 0 013.047.6 4.658 4.658 0 011.954 2.508.942.942 0 00.268.415c.124.111.274.185.435.213.626.113 1.194.45 1.604.955a2.874 2.874 0 01-.152 3.794 2.653 2.653 0 01-1.912.825.884.884 0 00-.637.275.958.958 0 00-.265.663c0 .249.095.488.264.664.17.176.399.274.638.274a4.41 4.41 0 002.924-1.157 4.748 4.748 0 001.496-2.86 4.847 4.847 0 00-.652-3.184 4.535 4.535 0 00-2.487-1.975zm-4.959 9.758a.532.532 0 00-.081-.16l-.108-.15a1.031 1.031 0 00-.298-.197.869.869 0 00-.52-.056.891.891 0 00-.463.254l-.108.15a.532.532 0 00-.081.16.617.617 0 00-.054.168 1.46 1.46 0 000 .188c-.004.06-.004.119 0 .178a.58.58 0 00.054.178.533.533 0 00.081.16l.108.15a.928.928 0 00.64.272.872.872 0 00.343-.075c.11-.048.21-.114.298-.197l.108-.15a.533.533 0 00.081-.16.58.58 0 00.054-.178c.01-.059.017-.119.018-.178a1.46 1.46 0 000-.188.611.611 0 00-.072-.169zm-.83-7.15c-.475 0-.941.13-1.353.377a2.763 2.763 0 00-.99 1.03.964.964 0 00-.091.716c.03.12.084.231.157.33.073.097.163.179.267.24a.871.871 0 00.682.09.908.908 0 00.544-.437.92.92 0 01.332-.344.884.884 0 011.09.15c.17.176.264.414.264.663a.958.958 0 01-.264.664.884.884 0 01-.637.275.884.884 0 00-.638.274.958.958 0 00-.264.664c0 .249.095.487.264.663.17.176.398.275.638.275.717 0 1.405-.296 1.912-.824a2.874 2.874 0 00.793-1.99c0-.747-.285-1.463-.793-1.991a2.653 2.653 0 00-1.912-.825z"
    />
  </svg>
)

export { DocAiIcon }
