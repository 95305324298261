const Style = {
  input: `float-left relative w-6 h-6 cursor-pointer mr-2 bg-none 
  border border-solid border-slate-200 bg-center appearance-none
  rounded-full checked:after:absolute checked:after:top-1/2 
  checked:after:left-1/2 checked:after:w-3 checked:after:h-3
  checked:after:rounded-full checked:after:bg-white 
  checked:after:-translate-y-1/2 checked:after:-translate-x-1/2 
  checked:bg-brand2-500`,

  label: 'cursor-pointer mt-px normal-case',
}

export default Style
