import { Tooltip as ToolTipComponent, TooltipContent, TooltipTrigger } from '@radix-ui/react-tooltip'

const Tooltip = ({ title, children, side = 'top', align = 'center', sideOffset = '2', ...props }) => (
  <ToolTipComponent>
    {title ? <TooltipTrigger asChild>{children}</TooltipTrigger> : children}
    <TooltipContent
      side={side}
      align={align}
      sideOffset={sideOffset}
      className="bg-brand-500 z-50 overflow-hidden text-white rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
      {...props}
    >
      <p>{title}</p>
    </TooltipContent>
  </ToolTipComponent>
)

export default Tooltip
