import { SettingsContext } from 'app/settings'
import { Button, Search } from 'components/lib'
import Animatedloader from 'components/loader/animatedloader'
import { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { categorizeDate, formatDate, getOS } from 'utils/helper'
import { HistoryIcon, NewIcon, Shield } from 'icons'
import { useWindowSize } from 'hooks'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { HINTS } from 'utils'
import ChatDownload from './chatDownload'

const Conversation = ({ name, time, onClick, active }) => {
  const timeFormat = 'DD MMM, h:mm A'
  return (
    <div
      onClick={onClick}
      // TODO:need to co-ordinate with design team and define color palette
      className={`rounded relative break-all  p-2 text-center flex items-center justify-center  hover:bg-[#F3F8FA] hover:text-[#333d4b] cursor-pointer group ${
        active ? 'bg-[#F3F8FA] text-brand-500' : ''
      }`}
    >
      <p className=" text-left w-[100%] text-[0.9rem] text-ellipsis overflow-hidden whitespace-nowrap">{name}</p>
      <span className="absolute text-[0.8rem] px-3 pt-[10px] rounded-md hidden right-0 bg-[linear-gradient(270deg,_#F3F8FA_52.1%,_rgba(243,_248,_250,_0.9)_100%)] h-[100%] text-[#475569] group-hover:block">
        {formatDate(time, timeFormat)}
      </span>
    </div>
  )
}

const NewChatButton = ({ enableRefresh, historyOpen, closeButtonStyle }) => {
  const os = getOS()
  const newChatHint = os === 'macos' ? HINTS.newChat.mac : HINTS.newChat.nonMac
  const handleNewChat = () => {
    if (enableRefresh) {
      window.location.reload()
    }
  }

  return (
    <Tippy content={`New chat (${newChatHint})`} arrow={roundArrow} animation="shift-away" inertia={true}>
      <div>
        <Button
          customIcon={<NewIcon className={'mx-auto'} />}
          color={'dark'}
          size={20}
          className={`${closeButtonStyle} ${historyOpen ? '!shadow-none ' : ''}`}
          action={handleNewChat}
        />
      </div>
    </Tippy>
  )
}
const ChatHistory = ({
  conversations,
  onOpen,
  loading,
  loadConversation,
  currentConversationId,
  setFileID,
  enableRefresh,
  isStreaming,
  className = '',
}) => {
  const os = getOS()
  const historyHint = os === 'macos' ? HINTS.history.mac : HINTS.history.nonMac
  const { isMobile } = useWindowSize()
  const storedOpenState = JSON.parse(localStorage.getItem('chatHistoryOpen') || 'false')
  const settingsContext = useContext(SettingsContext)
  const [historyOpen, setHistoryOpen] = useState(() => storedOpenState)
  const [categorizedConversations, setCategorizedConversations] = useState({})
  const [filteredConversations, setFilteredConversations] = useState(conversations)
  const chatTitle = historyOpen ? 'Hide Chat' : 'Chat history'

  const openButtonStyle =
    'bg-white rounded-full inline !h-8 !w-8 rounded-lg border [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
  const closeButtonStyle =
    ' bg-white rounded-full inline h-10 w-10 drop-shadow border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
  const buttonStyle = historyOpen ? openButtonStyle : closeButtonStyle

  useLayoutEffect(() => {
    if (onOpen && storedOpenState) {
      onOpen()
    }
  }, [])

  useEffect(() => {
    setFilteredConversations(conversations)
  }, [conversations])

  useEffect(() => {
    const categories = {}
    ;(filteredConversations || []).forEach((message) => {
      const dateCategory = categorizeDate(message.time)
      categories[dateCategory] = categories[dateCategory] || []
      categories[dateCategory].push(message)
    })

    setCategorizedConversations(categories)
  }, [filteredConversations])

  function search(term) {
    if (term === '') {
      setFilteredConversations(conversations)
    } else {
      const lowercasedTerm = term.toLowerCase()
      const filtered = conversations.filter((conversation) => conversation.name.toLowerCase().includes(lowercasedTerm))
      setFilteredConversations(filtered)
    }
  }

  const ensureChatSettingsClosed = () => {
    const chatButton = document.getElementById('chat-settings')
    if (chatButton && chatButton.getAttribute('data-toggle') === 'true') chatButton.click()
  }

  const handleToggleHistory = () => {
    setHistoryOpen((prevState) => {
      localStorage.setItem('chatHistoryOpen', JSON.stringify(!prevState))
      return !prevState
    })
    if (!historyOpen && isMobile) ensureChatSettingsClosed()
    if (!historyOpen && onOpen) onOpen()
  }

  const handleKeyPress = useCallback(
    (event) => {
      const isModifierKey = os === 'macos' ? event.metaKey : event.ctrlKey

      if (isModifierKey) {
        switch (event.key.toLowerCase()) {
          case ',':
            event.preventDefault()
            if (enableRefresh) {
              window.location.reload()
            }
            break
          case 'h':
            event.preventDefault()
            handleToggleHistory()
            break
          default:
            break
        }
      }
    },
    [os, enableRefresh, handleToggleHistory]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  return (
    <div
      style={historyOpen ? { boxShadow: '-1px 0 2px 1px rgba(0, 0, 0, 0.1)' } : {}}
      className={`${className} inline z-10 overflow-y-auto right-0 top-0 m-0 absolute p-2   ${
        historyOpen ? 'bg-white lg:w-[13rem] xl:w-[15rem] 2xl:w-[19rem] w-[19rem] h-full ' : 'h-fit w-fit'
      } `}
    >
      {settingsContext.isPermitted('chat_history') ? (
        <div className={!historyOpen ? 'text-end' : 'mb-2'}>
          <div
            className={
              historyOpen || !isMobile ? `flex gap-2 items-center justify-between mt-1` : 'flex flex-col gap-2'
            }
          >
            <Tippy content={`${chatTitle} (${historyHint})`} arrow={roundArrow} animation="shift-away" inertia={true}>
              <div>
                <Button
                  data-toggle={historyOpen}
                  id={'history-toggle'}
                  icon={historyOpen ? 'log-out' : undefined}
                  customIcon={
                    historyOpen ? undefined : <HistoryIcon className={'w-fit h-fit m-auto'} height={18} width={18} />
                  }
                  color={'dark'}
                  size={20}
                  className={buttonStyle}
                  action={handleToggleHistory}
                />
              </div>
            </Tippy>
            <div className="flex gap-2">
              <NewChatButton
                closeButtonStyle={closeButtonStyle}
                enableRefresh={enableRefresh}
                historyOpen={historyOpen}
              />
              {enableRefresh ? (
                <ChatDownload closeButtonStyle={closeButtonStyle} historyOpen={historyOpen} isStreaming={isStreaming} />
              ) : null}
              {historyOpen && (
                <Tippy content="AES-256 Encrypted" arrow={roundArrow} animation="shift-away" inertia={true}>
                  <div className={'w-fit h-fit my-auto opacity-50'}>
                    <Shield height={18} width={18} />
                  </div>
                </Tippy>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={!isMobile ? `flex gap-2 items-center justify-between mt-1` : 'flex flex-col gap-2'}>
          <NewChatButton closeButtonStyle={closeButtonStyle} enableRefresh={enableRefresh} historyOpen={historyOpen} />
          {enableRefresh ? (
            <ChatDownload closeButtonStyle={closeButtonStyle} historyOpen={historyOpen} isStreaming={isStreaming} />
          ) : null}
        </div>
      )}

      <div className={` bg-white ${!historyOpen && 'hidden'}`}>
        {!loading ? (
          <div>
            {conversations?.length > 0 ? (
              <div className={`rounded text-center mb-0`}>
                <Search
                  className={'border-none'}
                  buttonClassName={'!cursor-text hover:opacity-50'}
                  callback={search}
                  placeholder="Search past chats..."
                  inputClassName={'border-none focus:border-none focus:outline-none bg-transparent '}
                />
                {Object.keys(categorizedConversations).length > 0 ? (
                  <div className="max-h-[calc(100vh-12rem)] overflow-y-auto">
                    {Object.entries(categorizedConversations).map(([date, messages]) => (
                      <div key={date} className="chat-category">
                        <h3 className="text-left p-2 z-[1] text-[0.9rem] sticky top-0 bg-white  text-[#12367F] font-medium">
                          {date}
                        </h3>
                        {messages.map((message) => (
                          <Conversation
                            onClick={async () => {
                              if (setFileID) setFileID(message.file_id)
                              loadConversation(message.id)
                            }}
                            active={currentConversationId === message.id}
                            key={message.id}
                            name={message.name}
                            time={message.time}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="rounded bg-white p-6 text-center">No results found</div>
                )}
              </div>
            ) : (
              <div className="rounded bg-white p-6 text-center">No chat history</div>
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <Animatedloader />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatHistory
