/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import axios from 'axios'
import { AccountNav, Animate, Button, Card, Table, TitleRow, ViewContext, AuthContext } from 'components/lib'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AssignLicense from './assignLicense'
import useCallAPI from 'components/hooks/callApi'

export function Users(props) {
  const context = useContext(ViewContext)
  const navigate = useNavigate()

  const { user: userContext } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [callGetUser, data, loading] = useCallAPI({ url: '/api/account/users', method: 'GET' })
  const [users, setUsers] = useState([])
  useEffect(() => {
    callGetUser()
  }, [])
  const editUser = (data) => handleOpenLicense(true, data.id)

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: 'Delete User',
        form: {},
        buttonText: 'Delete User',
        text: `Are you sure you want to delete ${data.name}?`,
        url: `/api/user/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(data.name + ' was deleted', 'success', true)
        callback()
      }
    )
  }

  function deleteInvite(data, callback) {
    context.modal.show(
      {
        title: 'Delete Invite',
        form: {},
        buttonText: 'Delete Invite',
        text: `Are you sure you want to delete the invite for ${data.email}?`,
        url: `/api/user/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(`${data.email}'s invite was deleted`, 'success', true)
        callback()
      }
    )
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: '/api/invite',
        method: 'post',
        data: { email: data.email },
      })

      context.notification.show(`Invite re-sent to ${data.email}`, 'success', true)
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleOpenLicense = (isOpen, userId) => {
    if (searchParams.get('license') === isOpen) return

    const params = new URLSearchParams(window.location.search)
    if (isOpen) {
      params.set('license', isOpen)
      params.set('userId', userId)
    } else {
      params.delete('license')
      params.delete('userId')
      callGetUser()
    }
    setSearchParams(params)
  }

  useEffect(() => {
    // format the user list
    let list = []

    if (data?.data?.users?.length) {
      list = data.data.users.map((x) => {
        return {
          id: x.id,
          name: x.name,
          email: x.email,
          date_created: x.date_created,
          permission: x.permission,
          status: x.verified ? 'Verified' : 'Invited',
          licences: x.licenses === null ? '-' : x.licenses,
        }
      })

      // Sort users so that 'Invited' come last
      list.sort((a, _) => (a.status === 'Invited' ? 1 : -1))
    }

    setUsers(list)
  }, [data])

  // attach the per row actions for invites
  if (users.length) {
    users.forEach((u) => {
      if (u.status === 'Invited') {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite,
          edit: editUser,
        }
      }
      if (u.id === userContext?.user_id || u.permission === 'owner') {
        u.actions = {
          edit: editUser,
        }
      }
    })
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <TitleRow title="Manage Users">
          <Button
            small
            text="Add User"
            action={() => navigate('/account/users/invite')}
            color={props.destructive ? 'brand3' : 'brand3'}
          />
        </TitleRow>
        <AssignLicense onOpenChange={handleOpenLicense} open={searchParams.get('license') === 'true'} />
        <Card>
          <Table
            search
            className="restrict-width"
            data={users}
            loading={loading}
            show={['email', 'name', 'permission', 'status', 'licences']}
            badge={{
              col: 'status',
              color: 'blue',
              condition: [
                { value: 'verified', color: 'green' },
                { value: 'registered', color: 'blue' },
                { value: 'invited', color: 'orange' },
              ],
            }}
            actions={{
              edit: editUser,
              delete: deleteUser,
              // custom: userCustomActions,
            }}
          />
        </Card>
      </Animate>
    </Fragment>
  )
}
