const AvatarIcon = ({ width = 40, height = 40, fill = 'white', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" {...props}>
    <path
      fill={fill}
      d="M8 7a2 2 0 1 1 4 0a2 2 0 0 1-4 0m-.5 3h5a1.5 1.5 0 0 1 1.5 1.5c0 1.116-.459 2.01-1.212 2.615c-.741.595-1.735.885-2.788.885s-2.047-.29-2.788-.885C6.46 13.51 6 12.616 6 11.5A1.5 1.5 0 0 1 7.5 10M10 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-7 8a7 7 0 1 1 14 0a7 7 0 0 1-14 0"
    />
  </svg>
)

export { AvatarIcon }
