const TargetIcon = ({ width = 20, height = 20, fill = '#12367F', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill={fill}
      d="M19.09 9.09h-.962a8.193 8.193 0 0 0-7.219-7.218V.909a.91.91 0 0 0-1.818 0v.963A8.193 8.193 0 0 0 1.87 9.09H.91a.91.91 0 0 0 0 1.818h.963a8.193 8.193 0 0 0 7.219 7.22v.962a.91.91 0 0 0 1.818 0v-.963a8.193 8.193 0 0 0 7.22-7.219h.962a.909.909 0 0 0 0-1.818ZM10 16.365A6.364 6.364 0 1 1 16.364 10 6.37 6.37 0 0 1 10 16.364Z"
    />
    <path
      fill={fill}
      d="M10 6.364a3.636 3.636 0 1 0 0 7.272 3.636 3.636 0 0 0 0-7.272Zm0 5.454a1.818 1.818 0 1 1 0-3.636 1.818 1.818 0 0 1 0 3.636Z"
    />
  </svg>
)

export { TargetIcon }
