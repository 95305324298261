/***
 *
 *   ICON
 *   Render an icon from feather icons
 *
 *   PROPS
 *   color: dark/light/grey/green/orange/blue or hex code
 *   pack: icon pack to use, default is feathericons
 *   image: image to use (see: https://feathericons.com)
 *   className: inject a parent class object
 *
 **********/

import FeatherIcon from 'feather-icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import { useContext } from 'react'
import { ViewContext } from 'components/lib'

export function CopyIcon(props) {
  const viewContext = useContext(ViewContext)
  let color

  const packs = {
    fontawesome: FontAwesomeIcon,
    feather: FeatherIcon,
  }

  switch (props.color) {
    case 'light':
      color = '#FFFFFF'
      break

    case 'dark':
      color = '#758197'
      break

    case 'grey':
      color = '#ccc'
      break

    case 'green':
      color = '#8CC57D'
      break

    case 'blue':
      color = '#73B0F4'
      break

    case 'orange':
      color = '#F0AA61'
      break

    case 'red':
      color = '#c00000'
      break

    case 'purple':
      color = '#6363AC'
      break

    default:
      color = props.color
      break
  }

  if (props.pack === 'fontawesome') library.add(fab)

  const Icon = packs[props.pack || 'feather']

  return (
    <Tippy content="Copy to clipboard" animation="shift-away" arrow={roundArrow} inertia={true}>
      <div className={'inline top-0 float-right ml-2 hover:text-black/80 cursor-pointer'}>
        <Icon
          className="hover:text-black/80"
          color={color}
          icon="clipboard"
          size={props.size || 16}
          onClick={() => {
            navigator.clipboard.writeText(props.content)
            viewContext.notification.show('Copied to clipboard', 'success', true)
          }}
        />
      </div>
    </Tippy>
  )
}
