const RedirectIcon = ({ width = 14, height = 14, fill = '#475569', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 14 14" {...props}>
    <g clipPath="url(#a)">
      <path
        fill={fill}
        d="M10.111 14H2.333c-.623 0-1.209-.243-1.65-.684A2.318 2.318 0 0 1 0 11.666V3.89c0-.623.243-1.21.683-1.65a2.318 2.318 0 0 1 1.65-.683h3.111a.778.778 0 1 1 0 1.555h-3.11a.773.773 0 0 0-.55.228.773.773 0 0 0-.228.55v7.778a.773.773 0 0 0 .778.778h7.777c.208 0 .403-.082.55-.228a.773.773 0 0 0 .228-.55V8.556a.778.778 0 1 1 1.555 0v3.11c0 .624-.242 1.21-.683 1.65-.44.441-1.027.684-1.65.684ZM5.444 9.333a.778.778 0 0 1-.55-1.328l6.45-6.45H8.556a.778.778 0 1 1 0-1.555h4.666a.775.775 0 0 1 .543.221l.002.002.002.001v.001l.001.001.002.002a.027.027 0 0 0 .003.002v.001l.002.001.002.002a.775.775 0 0 1 .22.544v4.666a.778.778 0 1 1-1.555 0V2.656l-6.45 6.45a.775.775 0 0 1-.55.227Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
)

export { RedirectIcon }
