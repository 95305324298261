import { Chart } from 'react-chartjs-2'
import React, { useEffect, useState, useRef } from 'react'
import DownloadablePartWrapper from './DownloadablePartWrapper'

const ChartPart = React.memo(({ message, setCopyText, index }) => {
  useEffect(() => {
    setCopyText('[CHART]', index)
  }, [message, index, setCopyText])

  const [resizeCount, setResizeCount] = useState(0)
  const chartRef = useRef(null)

  useEffect(() => {
    const handleResize = () => setResizeCount((prev) => prev + 1)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  let json = null
  try {
    json = JSON.parse(message)
  } catch (e) {
    console.error(message)
    return <div>Could not load chart.</div>
  }

  return (
    <DownloadablePartWrapper
      onClick={(e) => {
        const downloadLink = document.createElement('a')
        downloadLink.href = chartRef.current.toBase64Image()
        downloadLink.download = 'chart.png'
        downloadLink.click()
      }}
      type="chart"
    >
      <Chart ref={chartRef} key={resizeCount} type={json.type} data={json.data} options={{ responsive: true }} />
    </DownloadablePartWrapper>
  )
})

export default ChartPart
