import React from 'react'
const Radio = ({ id, checked, name, onChange, label, value, className, ...props }) => {
  return (
    <label className={className + ' cursor-pointer flex items-center'}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
        {...props}
      />
      <div className="relative">
        <div
          className={`${!checked ? 'bg-[#CBD5E1]' : 'border-[1.5px] border-brand-500'} w-[1.125rem] h-[1.125rem]  rounded-full`}
        />
        {checked && (
          <div className="w-[1.125rem] h-[1.125rem] absolute top-0 left-0 flex items-center justify-center">
            <div className="w-[10px] h-[10px] bg-brand-500 rounded-full"></div>
          </div>
        )}
      </div>
      <span className="ml-2 text-sm text-gray-600">{label}</span>
    </label>
  )
}

export default Radio
