const CyborgIcon = ({ width = 18, height = 19, fill = '#fff', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 19" fill="none" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M15.569 5.446a2.675 2.675 0 0 0-2.676-2.676H5.109a2.675 2.675 0 0 0-2.676 2.676v7.783a2.675 2.675 0 0 0 2.676 2.676h7.784a2.675 2.675 0 0 0 2.676-2.676V5.446Zm-1.46 0v7.783c0 .672-.545 1.217-1.216 1.217H5.109a1.217 1.217 0 0 1-1.216-1.217V5.446c0-.672.545-1.217 1.216-1.217h7.784c.671 0 1.216.545 1.216 1.217Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M10.558 -1.46ZM14.839 7.985h2.432a.73.73 0 0 0 0-1.46H14.84a.73.73 0 0 0 0 1.46ZM7.648 3.5V1.067a.73.73 0 0 0-1.459 0V3.5a.73.73 0 0 0 1.46 0ZM6.189 15.175v2.433a.73.73 0 0 0 1.46 0v-2.433a.73.73 0 0 0-1.46 0ZM3.163 6.526H.731a.73.73 0 0 0 0 1.459h2.432a.73.73 0 0 0 0-1.46ZM14.839 12.15h2.432a.73.73 0 0 0 0-1.46H14.84a.73.73 0 0 0 0 1.46ZM11.813 3.5V1.067a.73.73 0 0 0-1.46 0V3.5a.73.73 0 0 0 1.46 0ZM10.353 15.175v2.433a.73.73 0 0 0 1.46 0v-2.433a.73.73 0 0 0-1.46 0ZM3.163 10.69H.731a.73.73 0 0 0 0 1.46h2.432a.73.73 0 0 0 0-1.46Z"
      clipRule="evenodd"
    />
  </svg>
)

export { CyborgIcon }
