const ProvTaxIcon = ({ width = 14, height = 18, fill = '#fff', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 14 18" {...props}>
    <path
      fill={fill}
      d="M7 17.5c-.15 0-.3-.038-.434-.115l-2.629-1.502-2.628 1.502A.875.875 0 010 16.625v-14c0-.701.273-1.36.769-1.856A2.608 2.608 0 012.625 0h8.75c.701 0 1.36.273 1.856.769S14 1.924 14 2.625v14a.875.875 0 01-1.31.76l-2.627-1.502-2.629 1.502A.875.875 0 017 17.5zM3.937 14c.15 0 .3.038.435.115L7 15.617l2.628-1.502a.875.875 0 01.869 0l1.753 1.002V2.625a.87.87 0 00-.256-.619.87.87 0 00-.619-.256h-8.75a.87.87 0 00-.619.256.87.87 0 00-.256.619v12.492l1.753-1.002A.874.874 0 013.937 14zm5.25-2.625c-.35 0-.68-.136-.927-.384a1.305 1.305 0 01-.385-.928c0-.35.136-.68.384-.928.249-.249.578-.385.928-.385.35 0 .68.136.928.384.249.249.385.578.385.928 0 .35-.136.68-.384.928a1.305 1.305 0 01-.929.385zm-4.812 0a.875.875 0 01-.619-1.494l5.25-5.25a.875.875 0 011.238 1.238l-5.25 5.25a.872.872 0 01-.619.256zM4.812 7c-.35 0-.68-.137-.928-.385a1.304 1.304 0 01-.384-.928c0-.35.137-.68.385-.928.247-.247.577-.384.928-.384.35 0 .68.137.928.384.248.248.384.578.384.928 0 .351-.136.68-.384.929A1.304 1.304 0 014.812 7z"
    />
  </svg>
)

export { ProvTaxIcon }
