import React, { useState, useEffect, useCallback } from 'react'
import { parse, format, addDays, isWeekend } from 'date-fns'
import { Event } from 'components/lib'

const dateMapping = {
  10: ['28 March', '28 July', '28 November', '7 September', '7 November'],
  11: ['7 May', '28 August', '15 January', '7 October', '7 December'],
  12: ['28 May', '28 September', '28 January', '7 November', '15 January'],
  '01': ['28 June', '28 October', '28 February', '7 December', '7 February'],
  '02': ['28 July', '28 November', '28 March', '15 January', '7 March'],
  '03': ['28 August', '15 January', '7 May', '7 February', '7 April'],
  '04': ['28 September', '28 January', '28 May', '7 February', '7 April'],
  '05': ['28 October', '28 February', '28 June', '7 February', '7 April'],
  '06': ['28 November', '28 March', '28 July', '7 February', '7 April'],
  '07': ['15 January', '7 May', '28 August', '7 February', '7 April'],
  '08': ['28 January', '28 May', '28 September', '7 February', '7 April'],
  '09': ['28 February', '28 June', '28 October', '7 February', '7 April'],
}

const dateMappingNumbers = {
  10: [3, 7, 11, 9, 11],
  11: [5, 8, 1, 10, 12],
  12: [5, 9, 1, 11, 1],
  '01': [6, 10, 2, 12, 2],
  '02': [7, 11, 3, 1, 3],
  '03': [8, 1, 5, 2, 4],
  '04': [9, 1, 5, 2, 4],
  '05': [10, 2, 6, 2, 4],
  '06': [11, 3, 7, 2, 4],
  '07': [1, 5, 8, 2, 4],
  '08': [1, 5, 9, 2, 4],
  '09': [2, 6, 10, 2, 4],
}

export function ProvTax() {
  // Public holidays

  const [publicHolidays, setPublicHolidays] = useState({
    2021: [
      '1 January',
      '4 January',
      '8 Febuary',
      '2 April',
      '5 April',
      '26 April',
      '7 June',
      '25 October',
      '27 December',
      '28 December',
    ],
    2022: [
      '3 January',
      '4 January',
      '7 Febuary',
      '15 April',
      '18 April',
      '25 April',
      '6 June',
      '24 June',
      '26 September',
      '24 October',
      '26 December',
      '27 December',
    ],
    2023: [
      '2 January',
      '3 January',
      '6 Febuary',
      '7 April',
      '10 April',
      '25 April',
      '5 June',
      '14 July',
      '23 October',
      '25 December',
      '26 December',
    ],
    2024: [
      '1 January',
      '2 January',
      '6 Febuary',
      '29 March',
      '1 April',
      '25 April',
      '3 June',
      '28 June',
      '28 October',
      '25 December',
      '26 December',
    ],
    2025: [
      '1 January',
      '2 January',
      '6 Febuary',
      '18 April',
      '21 April',
      '25 April',
      '2 June',
      '20 June',
      '27 October',
      '25 December',
      '26 December',
    ],
  })

  // adjust the dates in they fall on a holiday or weekend
  const adjustDateIfHolidayOrWeekend = (dateString, year) => {
    let date = parse(`${dateString} ${year}`, 'd MMMM yyyy', new Date())
    while (isWeekend(date) || publicHolidays[year].includes(format(date, 'd MMMM'))) {
      date = addDays(date, 1)
    }
    return format(date, 'd MMMM')
  }

  const triggerEventForProvCalc = () => {
    Event('prompt_submitted', {
      endpoint: `/api/provtax}`,
    })
  }

  // helper function to convrt date strings to actual date objects
  const convertToDateObject = (dateString, year) => {
    const parsedDate = parse(`${dateString} ${year}`, 'd MMMM yyyy', new Date())

    // Log
    console.log(`Parsed Date for ${dateString} ${year}:`, parsedDate)

    return parsedDate
  }

  //function to get the soonest date, given a set of dates
  const getSoonestDate = (dates, year) => {
    const today = new Date()

    // Log
    console.log('Today:', today)

    const dateObjects = dates.map((date) => convertToDateObject(date, year))

    // Log
    console.log('Date Objects:', dateObjects)

    const sortedDates = dateObjects.sort((a, b) => a.getTime() - b.getTime())

    // Log
    console.log('Sorted Dates:', sortedDates)

    const soonest = sortedDates.find((date) => date.getTime() >= today.getTime())

    // Log
    console.log('Soonest Date:', soonest)

    return soonest
  }

  // determine which instalment corresponds
  const getInstalmentForSoonestDate = (soonestDate, month) => {
    const dates = dateMapping[month]
    if (dates) {
      for (let i = 0; i < dates.length; i++) {
        if (convertToDateObject(dates[i], selectedYear).getTime() === soonestDate.getTime()) {
          switch (i) {
            case 0:
              return 'P1'
            case 1:
              return 'P2'
            case 2:
              return 'P3'
            case 3:
              return 'Terminal Tax'
            default:
              return '' // This case shouldn't occur, but just in case
          }
        }
      }
    }
    return ''
  }

  // reset inputs
  const resetInputs = () => {
    setIsFiledChecked(false)
    setRIT1Value('')
    setRIT2Value('')
    setTaxPaidValue('')
    setPd1('')
    setPd2('')
    setPd3('')
  }

  // calc for terminal tax
  const [incomeTaxForYear, setIncomeTaxForYear] = useState('')

  // tax agent ticked state
  const [isTaxAgentChecked, setIsTaxAgentChecked] = useState(false)

  // State to control which provisional tax option is selected
  const [provTaxOption, setProvTaxOption] = useState('')

  const [isFiledChecked, setIsFiledChecked] = useState(false)

  const [RIT1Value, setRIT1Value] = useState('')
  const [RIT2Value, setRIT2Value] = useState('')
  const [taxPaidValue, setTaxPaidValue] = useState('')

  const [clientContactValue, setClientContactValue] = useState('')
  const [client, setClient] = useState('')
  const [clientAbb, setClientAbb] = useState('')
  const [IRDnum, setIRDnum] = useState('')

  let RIT1Result = (RIT1Value * 1.1) / 3
  let RIT2Result = (RIT2Value * 1.05) / 3

  if (provTaxOption === 'P1') {
    RIT1Result *= 1
    RIT2Result *= 1
  } else if (provTaxOption === 'P2') {
    RIT1Result *= 2
    RIT2Result *= 2
  } else if (provTaxOption === 'P3') {
    RIT1Result *= 3
    RIT2Result *= 3
  }

  // truncate numbers
  RIT1Result = Math.floor(RIT1Result)
  RIT2Result = Math.floor(RIT2Result)
  let RIT1TaxLiability = RIT1Result - taxPaidValue
  let RIT2TaxLiability = RIT2Result - taxPaidValue

  // set date states
  const [selectedMonth, setSelectedMonth] = useState('')
  const [pd1, setPd1] = useState('')
  const [pd2, setPd2] = useState('')
  const [pd3, setPd3] = useState('')
  const [td1, setTd1] = useState('')
  const [td2, setTd2] = useState('')

  // set intitial date states
  const [ipd1, setIpd1] = useState('')
  const [ipd2, setIpd2] = useState('')
  const [ipd3, setIpd3] = useState('')
  const [itd1, setItd1] = useState('')
  const [itd2, setItd2] = useState('')

  const [ipd1n, setIpd1n] = useState('')
  const [ipd2n, setIpd2n] = useState('')
  const [ipd3n, setIpd3n] = useState('')
  const [itd1n, setItd1n] = useState('')
  const [itd2n, setItd2n] = useState('')

  // selected tax year state
  const [selectedYear, setSelectedYear] = useState('')

  // relevant year (Year for workingday/holiday calc purposes) = the selected year if Ipd of chosen instalment is before the balance date. If after, selected year +1.

  // funded to determine the default year
  useEffect(() => {
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1 // January is 0 in JS
    const currentYear = currentDate.getFullYear()

    if (selectedMonth === '' || Number(selectedMonth) >= currentMonth) {
      setSelectedYear(currentYear.toString())
    } else {
      setSelectedYear((currentYear + 1).toString())
    }
  }, [selectedMonth])

  // update adjusted dates
  const updateDates = useCallback(
    (month) => {
      let yearUsed = selectedYear
      let map = {
        P1: ipd1n,
        P2: ipd2n,
        P3: ipd3n,
        T1: itd1n,
        T2: itd2n,
      }

      if (map[provTaxOption] > parseInt(selectedMonth)) {
        yearUsed = `${parseInt(yearUsed) - 1}`
      }

      const dates = dateMapping[month]
      if (dates) {
        setPd1(adjustDateIfHolidayOrWeekend(dates[0], yearUsed))
        setPd2(adjustDateIfHolidayOrWeekend(dates[1], yearUsed))
        setPd3(adjustDateIfHolidayOrWeekend(dates[2], yearUsed))
        setTd1(adjustDateIfHolidayOrWeekend(dates[3], yearUsed))
        setTd2(adjustDateIfHolidayOrWeekend(dates[4], yearUsed))
      }
    },
    [selectedYear]
  )

  // update initial dates
  const updatePureDates = useCallback(
    (month) => {
      const dates = dateMapping[month]
      if (dates) {
        setIpd1(dates[0])
        setIpd2(dates[1])
        setIpd3(dates[2])
        setItd1(dates[3])
        setItd2(dates[4])
      }
      const datesNumber = dateMappingNumbers[month]
      if (datesNumber) {
        setIpd1n(datesNumber[0])
        setIpd2n(datesNumber[1])
        setIpd3n(datesNumber[2])
        setItd1n(datesNumber[3])
        setItd2n(datesNumber[4])
      }
    },
    [selectedYear]
  )

  // update dates when month is changed
  const handleMonthChange = (event) => {
    const month = event.target.value
    setSelectedMonth(month)
    updateDates(month)
    updatePureDates(month)
    const soonestDate = getSoonestDate(dateMapping[month], selectedYear)
    const instalment = getInstalmentForSoonestDate(soonestDate, month)
    setProvTaxOption(instalment)
  }

  useEffect(() => {
    updateDates(selectedMonth)
  }, [provTaxOption, selectedMonth, updateDates])

  // format numbers
  const formatNumber = (num) => {
    if (!num || isNaN(num)) {
      return ''
    }
    return '$' + parseInt(num).toLocaleString()
  }

  const parseNumber = (str) => {
    return str.replace(/\D/g, '') // Remove non-digits
  }

  //format IRD numbers

  const handleInputChange = (e) => {
    const value = e.target.value

    // Remove any characters that aren't digits or hyphens
    const cleaned = value.replace(/[^0-9]/g, '') // Only removing non-digit characters

    let formatted = cleaned

    if (cleaned.length <= 2) {
      formatted = cleaned
    } else if (cleaned.length <= 5) {
      formatted = `${cleaned.substr(0, 2)}-${cleaned.substr(2)}`
    } else if (cleaned.length <= 8) {
      formatted = `${cleaned.substr(0, 2)}-${cleaned.substr(2, 3)}-${cleaned.substr(5)}`
    } else if (cleaned.length === 9) {
      formatted = `${cleaned.substr(0, 3)}-${cleaned.substr(3, 3)}-${cleaned.substr(6)}`
    }

    setIRDnum(formatted)
  }

  // tax due
  const taxDue = formatNumber(
    provTaxOption === 'Terminal Tax'
      ? incomeTaxForYear - taxPaidValue <= 0
        ? 0
        : incomeTaxForYear - taxPaidValue
      : RIT2Value === ''
        ? RIT1TaxLiability <= 0
          ? 0
          : RIT1TaxLiability
        : RIT2TaxLiability <= 0
          ? 0
          : RIT2TaxLiability
  )

  // logic to create a variable for the selected month
  const monthNames = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  }

  const selectedMonthName = monthNames[selectedMonth]

  const lastDayOfMonth = {
    '01': 31, // January
    '02': 28, // February (29 for leap years, not accounted for here)
    '03': 31, // March
    '04': 30, // April
    '05': 31, // May
    '06': 30, // June
    '07': 31, // July
    '08': 31, // August
    '09': 30, // September
    10: 31, // October
    11: 30, // November
    12: 31, // December
  }

  // Get the last day for the selected month
  const monthDay = lastDayOfMonth[selectedMonth]

  //
  const td3 = isTaxAgentChecked ? td2 : td1
  const itd3 = isTaxAgentChecked ? itd2 : itd1

  // tailwind css for boxes
  const boxSizing = '2xl:w-[11rem] w-[8rem]'
  const boxStyle1 = `${boxSizing} bg-slate-100 p-2 rounded-lg`
  const boxStyle2 = `${boxSizing} bg-slate-100 py-2 px-3 rounded-lg`
  const boxStyle3 = `${boxSizing} bg-slate-100 py-2 px-3 rounded-lg transition-colors duration-300 ease-in-out text-blue-700 font-medium`

  // display to page JSX

  return (
    <div className="flex flex-col h-full p-[1.5rem] w-full no-scrollbar overflow-y-auto">
      <div className="lg:w-[67%] md:w-[90%] self-center relative webfill flex flex-col lg:flex-row justify-center">
        <div className="flex flex-col lg:w-1/4 pr-5">
          <h1 className="ml-5 mt-14"></h1>
          <div className="flex flex-col bg-white rounded-lg p-4">
            <label className="flex flex-col">
              <p className="text-xs ml-2">Balance date</p>
              <select className={boxStyle1} name="Balance Month" value={selectedMonth} onChange={handleMonthChange}>
                <option value="">--Select--</option>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </label>

            <label className="flex flex-col mt-3">
              <p className="text-xs ml-2">Instalment</p>
              <select
                className={boxStyle1}
                value={provTaxOption}
                onChange={(e) => {
                  setProvTaxOption(e.target.value)
                  resetInputs() // Reset all inputs and selections when provTaxOption changes
                  setIsFiledChecked(false) // Reset the checkbox state
                }}
              >
                <option value="">--Select--</option>
                <option value="P1">First instalment</option>
                <option value="P2">Second instalment</option>
                <option value="P3">Third instalment</option>
                <option value="Terminal Tax">Terminal Tax</option>
              </select>
            </label>
          </div>

          {provTaxOption !== '' && selectedMonth !== '' && (
            <>
              <div className="flex flex-col bg-white rounded-lg p-4 mt-5">
                {/* only show if calculating terminal tax */}
                {provTaxOption === 'Terminal Tax' && (
                  <label>
                    <p className="text-xs ml-2">Does the entity have a Tax Agent?</p>
                    <select
                      className={boxStyle1}
                      name="taxAgent"
                      value={isTaxAgentChecked ? 'yes' : 'no'}
                      onChange={(e) => setIsTaxAgentChecked(e.target.value === 'yes')}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                )}

                <label>
                  <p className="text-xs ml-2">Tax year</p>
                  <select
                    className={boxStyle1}
                    name="Tax Year"
                    value={selectedYear}
                    onChange={(e) => {
                      setSelectedYear(e.target.value)
                    }}
                  >
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </label>

                {provTaxOption !== 'Terminal Tax' && (
                  <label>
                    <p className="text-xs ml-2 mt-3">
                      {selectedYear && `${parseInt(selectedYear) - 2}`} residual income tax
                    </p>
                    <input
                      className={boxStyle2}
                      type="text" // change to text type since we're adding non-numeric characters
                      name="RIT1"
                      placeholder="Amount"
                      min="0"
                      value={formatNumber(RIT1Value)}
                      onChange={(e) => setRIT1Value(parseNumber(e.target.value))}
                    />
                  </label>
                )}

                {provTaxOption !== 'Terminal Tax' && (
                  <label>
                    <p className="text-xs ml-2 mt-3">Has the last tax year's return been filed yet?</p>
                    <select
                      className={boxStyle1}
                      name="filed"
                      value={isFiledChecked ? 'Yes' : 'No'}
                      onChange={(e) => {
                        const selectedValue = e.target.value

                        if (selectedValue === 'Yes') {
                          setIsFiledChecked(true)
                        } else if (selectedValue === 'No') {
                          setIsFiledChecked(false)
                          setRIT2Value('') // set the RIT2Value to 0
                        }
                      }}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </label>
                )}

                {/* Conditional rendering for RIT2 input */}
                {provTaxOption !== 'Terminal Tax' && isFiledChecked && (
                  <label>
                    <p className="text-xs ml-2 mt-3">
                      {selectedYear && `${parseInt(selectedYear) - 1}`} residual income tax
                    </p>
                    <input
                      className={boxStyle2}
                      type="text" // change to text type since we're adding non-numeric characters
                      name="RIT2"
                      placeholder="Amount"
                      min="0"
                      value={formatNumber(RIT2Value)}
                      onChange={(e) => setRIT2Value(parseNumber(e.target.value))}
                    />
                  </label>
                )}

                {provTaxOption !== 'P1' && (
                  <label>
                    <p className="text-xs ml-2 mt-3">
                      Provisional tax paid (so far in {selectedYear && `${parseInt(selectedYear)}`})
                    </p>
                    <input
                      className={boxStyle2}
                      type="text" // change to text type since we're adding non-numeric characters
                      name="paid"
                      placeholder="Amount Paid"
                      min="0"
                      value={formatNumber(taxPaidValue)}
                      onChange={(e) => setTaxPaidValue(parseNumber(e.target.value))}
                    />
                  </label>
                )}

                {provTaxOption === 'Terminal Tax' && (
                  <label>
                    <p className="text-xs ml-2 mt-3">Income Tax for the year</p>
                    <input
                      className={boxStyle2}
                      type="text" // change to text type since we're adding non-numeric characters
                      name="income_tax"
                      placeholder="income tax"
                      value={formatNumber(incomeTaxForYear)}
                      onChange={(e) => setIncomeTaxForYear(parseNumber(e.target.value))}
                    />
                  </label>
                )}

                {/* VALUES FOR DEBUGGING */}

                {/* <div>
  <p>Provisional tax liability, due to RIT1: {RIT1Result}</p>
  {provTaxOption !== "Terminal Tax" && isFiledChecked && <p>Provisional tax liability, due to RIT2: {RIT2Result}</p>}
</div> */}

                <div>
                  {provTaxOption === 'P1' && (
                    <>
                      <p className="text-xs ml-2 mt-3">Date the first instalment is due:</p>
                      <div className={boxStyle3}>{pd1}</div>
                    </>
                  )}
                  {provTaxOption === 'P2' && (
                    <>
                      <p className="text-xs ml-2 mt-3">Date the second instalment is due:</p>
                      <div className={boxStyle3}>{pd2}</div>
                    </>
                  )}
                  {provTaxOption === 'P3' && (
                    <>
                      <p className="text-xs ml-2 mt-3">Date the final instalment is due:</p>
                      <div className={boxStyle3}>{pd3}</div>
                    </>
                  )}
                  {provTaxOption === 'Terminal Tax' && (
                    <>
                      <p className="text-xs ml-2 mt-3">Date terminal tax is due</p>
                      <div className={boxStyle3}>{td3}</div>
                    </>
                  )}
                </div>

                <div>
                  <p className="text-xs ml-2 mt-3">Tax due</p>
                  <div className={boxStyle3}>{taxDue ? taxDue : '$0'}</div>
                </div>
              </div>

              {provTaxOption !== 'Terminal Tax' && (
                <div className="flex flex-col bg-white rounded-lg p-4 mt-5">
                  <label>
                    <p className="text-xs ml-2">Client contact</p>
                    <input
                      className={boxStyle2}
                      name="clientContact"
                      placeholder="First Name"
                      value={clientContactValue}
                      onChange={(e) => setClientContactValue(e.target.value)}
                    />
                  </label>
                  <label>
                    <p className="text-xs ml-2 mt-3">Client</p>
                    <input
                      className={boxStyle2}
                      name="client"
                      placeholder="ABC Limited"
                      value={client}
                      onChange={(e) => setClient(e.target.value)}
                    />
                  </label>
                  <label>
                    <p className="text-xs ml-2 mt-3">Client abbreviation</p>
                    <input
                      className={boxStyle2}
                      name="clientabb"
                      placeholder="ABCL"
                      value={clientAbb}
                      onChange={(e) => setClientAbb(e.target.value)}
                    />
                  </label>
                  <label>
                    <p className="text-xs ml-2 mt-3">Client IRD number</p>
                    <input
                      className={boxStyle2}
                      name="IRDnum"
                      type="text"
                      placeholder="IRD Number"
                      value={IRDnum}
                      onChange={handleInputChange}
                      onBlur={triggerEventForProvCalc}
                      maxLength={11} // "999-999-999".length = 11
                    />
                  </label>
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex flex-col lg:w-3/4 pr-5">
          {provTaxOption !== 'Terminal Tax' && (
            <div className="flex flex-col bg-white rounded-lg p-4 mt-14 text-black/90" contenteditable="true">
              <p>Dear {clientContactValue ? clientContactValue : 'XXX'},</p>
              <br />
              <p>I trust you're keeping well.</p>
              <br />
              <p>
                I'm writing to notify you that the {provTaxOption === '' && 'XXX instalment'}
                {provTaxOption === 'P1' && 'first instalment'}
                {provTaxOption === 'P2' && 'second instalment'}
                {provTaxOption === 'P3' && 'final instalment'} of provisional tax for the year ending {monthDay}{' '}
                {selectedMonthName} {selectedYear} for {client ? client : 'XXX'} (“{clientAbb ? clientAbb : 'XXX'}”) is
                due on
                {provTaxOption === '' && 'XXX'}
                {provTaxOption === 'P1' && ' ' + ipd1}
                {provTaxOption === 'P2' && ' ' + ipd2}
                {provTaxOption === 'P3' && ' ' + ipd3}
                {provTaxOption === 'Terminal Tax' && ' ' + itd3}
                {provTaxOption === 'P1'
                  ? pd1 !== ipd1
                    ? ` but may be paid by the next working day, ${pd1}, without incurring any extra costs.`
                    : '.'
                  : null}
                {provTaxOption === 'P2'
                  ? pd2 !== ipd2
                    ? ` but may be paid by the next working day, ${pd2}, without incurring any extra costs.`
                    : '.'
                  : null}
                {provTaxOption === 'P3'
                  ? pd3 !== ipd3
                    ? ` but may be paid by the next working day, ${pd3}, without incurring any extra costs.`
                    : '.'
                  : null}
                {provTaxOption === 'Terminal Tax'
                  ? td3 !== itd3
                    ? ` but may be paid by the next working day, ${td3}, without incurring any extra costs.`
                    : '.'
                  : null}
              </p>
              <br />
              <p>
                {clientAbb ? clientAbb : 'XXX'} has the following options available for calculating this instalment
                payment:
              </p>
              <br />
              <ol className="list-disc ml-5">
                <li>the standard uplift method; or</li>
                <li>the estimation method.</li>
              </ol>
              <br />
              <b>Standard Uplift Method</b>
              <p>
                The standard uplift method calculates provisional tax due based on historical residual income tax
                liability ("RIT").{' '}
                {isFiledChecked === false
                  ? `We've filed the ${parseInt(selectedYear) - 2} income tax return for ${clientAbb ? clientAbb : 'XXX'}, but the ${parseInt(selectedYear) - 1} income tax return is still pending. This means that`
                  : `Because the ${parseInt(selectedYear) - 1} income tax return has been filed,`}{' '}
                the payable amount is derived from an uplift of {clientAbb ? clientAbb : 'XXX'}'s{' '}
                {isFiledChecked === false ? parseInt(selectedYear) - 2 : parseInt(selectedYear) - 1} RIT. Based on the
                below calculations, we recommend a payment of at least {taxDue ? taxDue : '$0'} before {pd1}.
              </p>
              <br />
              <p>
                {selectedYear} {provTaxOption === '' && ''}
                {provTaxOption === 'P1' && 'First Instalment'}
                {provTaxOption === 'P2' && 'Second Instalment'}
                {provTaxOption === 'P3' && 'Final Instalment'} Calculation:
                <br />= (
                {isFiledChecked === false
                  ? `110% x ${parseInt(selectedYear) - 2}`
                  : `105% x ${parseInt(selectedYear) - 1}`}{' '}
                RIT) x ({provTaxOption === '' && 'X'}
                {provTaxOption === 'P1' && '1'}
                {provTaxOption === 'P2' && '2'}
                {provTaxOption === 'P3' && '3'}/3) – payments/transfers made so far
                <br />= (
                {isFiledChecked === false ? `110% x ${formatNumber(RIT1Value)}` : `105% x ${formatNumber(RIT2Value)}`})
                x ({provTaxOption === '' && 'X'}
                {provTaxOption === 'P1' && '1'}
                {provTaxOption === 'P2' && '2'}
                {provTaxOption === 'P3' && '3'}/3) – {taxPaidValue ? formatNumber(taxPaidValue) : '$0'}
                <br />= {taxDue ? taxDue : '$0'}
              </p>
              <br />
              <p>
                If {clientAbb ? clientAbb : 'XXX'} pays its provisional tax liability on time and in full using the
                standard uplift method, late payment penalties will not apply. Additionally, use of money interest
                (UOMI) will only be calculated from the due date of the final instalment (on the difference between the
                total provisional tax paid and the amount due as per your tax return). The current UOMI rates are 10.91%
                for underpayments and 4.67% for overpayments.
              </p>
              <br />
              <b>Estimation Method</b>
              <p>
                If you believe that {clientAbb ? clientAbb : 'XXX'}'s {selectedYear} tax liability will be materially
                lower than the amount determined using the standard uplift method (i.e., materially less than{' '}
                {isFiledChecked === false
                  ? `${formatNumber(Math.floor(RIT1Value * 1.1))}`
                  : `${formatNumber(Math.floor(RIT2Value * 1.05))}`}
                ), you might consider paying a lesser amount. In such a case, we advise filing an estimate with Inland
                Revenue.
              </p>
              <br />
              <p>
                Please be aware that the estimate should be fair and reasonable, based on 28% of the anticipated taxable
                income for the year ending {monthDay} {selectedMonthName} {selectedYear}. Where payment based on an
                estimate proves insufficient and Inland Revenue considers that reasonable care was not taken, a
                shortfall penalty of 20% (or higher) may be imposed.
              </p>
              <br />
              <p>
                This option would also expose {clientAbb ? clientAbb : 'XXX'} to UOMI from the 1st instalment date
                (rather than the final instalment like under the standard uplift method). Given these considerations,
                the estimate option should only be used in special cases.
              </p>
              <br />
              <p>
                <strong>Payment</strong>
              </p>
              <p>
                If you would like help in filing an estimate please get in touch, otherwise please make payment to
                Inland Revenue using the details noted below:
              </p>
              <br />
              <ul>
                <li>
                  <strong>Bank:</strong> Westpac
                </li>
                <li>
                  <strong>Account Number:</strong> 03 0049 0001100 27
                </li>
                <li>
                  <strong>Account Name:</strong> INLAND REVENUE DEPT
                </li>
                <li>
                  <strong>Payment Particulars:</strong> {IRDnum ? IRDnum : 'XXX-XXX-XXX'}
                </li>
                <li>
                  <strong>Payee Code:</strong> INC 3103{selectedYear}
                </li>
              </ul>
              <br />
              <p>
                The payee code refers to the standard financial year ending 31 March {selectedYear}. Do note that this
                code remains applicable regardless of {clientAbb ? clientAbb : 'XXX'}'s balance date.
              </p>
              <br />
              <p>
                If you have any queries or require further clarification on the above, please don't hesitate to reach
                out. Otherwise, please confirm the amount of provisional tax that will be settled by
                {provTaxOption === '' && 'XXX'}
                {provTaxOption === 'P1' && ' ' + pd1}
                {provTaxOption === 'P2' && ' ' + pd2}
                {provTaxOption === 'P3' && ' ' + pd3}
                {provTaxOption === 'Terminal Tax' && ' ' + td3}.
              </p>
              <br />
              <p>Warm regards,</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
