const MinusIcon = ({ width = 14, height = 2, fill = '#475569', ...props }) => (
  <svg width={width} height={height} viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.0488 1.90238H0.95119C0.425753 1.90238 0 1.47663 0 0.95119C0 0.425753 0.425753 0 0.95119 0H13.0488C13.5742 0 14 0.425753 14 0.95119C14 1.47663 13.5742 1.90238 13.0488 1.90238Z"
      fill={fill}
    />
  </svg>
)

export { MinusIcon }
