import { Fragment, useState, useContext } from 'react'
import { Card, Form, Icon, Button, ViewContext } from 'components/lib'
import Style from './feedback.tailwind.js'

export function MessageFeedback(props) {
  // context & state
  const context = useContext(ViewContext)
  const [showComments, setShowComments] = useState(false)
  const [rating, setRating] = useState(null)

  // maps
  const icons = ['smile', 'meh', 'frown']
  const ratings = ['positive', 'neutral', 'negative']
  const colors = ['green', 'orange', 'red']

  function saveRating(rating) {
    setRating(rating)
    setShowComments(true)
  }
  return (
    <div
      className={Style.feedback}
      style={{
        position: 'relative',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -30%)',
        border: '0px',
        zIndex: 1000,
      }}
    >
      <Card title="How was your answer?" className={Style.feedback}>
        <Button
          icon="x"
          color="dark"
          position="absolute"
          className={Style.close}
          size={16}
          action={(e) => context.modal.hide(true)}
          style={{ width: '1rem' }}
        />
        <Fragment>
          <section className={Style.rating}>
            {icons.map((icon, index) => {
              return (
                <div key={index} className={Style.ratingButton} onClick={() => saveRating(index)}>
                  <Icon image={icon} color={index === rating ? colors[index] : 'dark'} className={Style.ratingIcon} />
                </div>
              )
            })}
          </section>

          {showComments && (
            <>
              <div className="inline-block py-1 text-xs rounded text-justify" style={{ fontSize: '12px' }}>
                Note: Submitting feedback will save the content of your current chat to our system for confidential
                manual review.
              </div>
              <Form
                inputs={{
                  chatHistory: {
                    type: 'hidden',
                    value: props.chatHistory,
                  },
                  rating: {
                    type: 'hidden',
                    value: ratings[rating],
                  },
                  anonymous: {
                    type: 'checkbox',
                    options: ['Stay anonymous'],
                    default: ['Stay anonymous'],
                    class: 'w-full',
                  },
                  comment: {
                    type: 'textarea',
                    placeholder: 'Enter your feedback here',
                  },
                }}
                method="POST"
                url="/api/feedback"
                updateOnChange
                className={Style.form}
                buttonText="Send Feedback"
                onChange={(e) => {
                  return false
                }}
                callback={(e) => {
                  context.notification.show(
                    'Thank you, your feedback is most appreciated.',
                    'success',
                    true,
                    'toast',
                    'heart'
                  )
                }}
              />
            </>
          )}
        </Fragment>
      </Card>
    </div>
  )
}
