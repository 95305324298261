import { Dashboard } from 'views/dashboard/dashboard'
import { Help } from 'views/dashboard/help'
import { LawCyborg } from 'views/dashboard/lawCyborg'
import { AiGeneral } from 'views/dashboard/aiGeneral'
import { DocUpload } from 'views/dashboard/docupload'
import { DepFinder } from 'views/dashboard/depfinder'
import { ProvTax } from 'views/dashboard/provtax'
import { Rnd } from 'views/dashboard/rnd'
import { OnboardingView } from 'views/onboarding/onboarding'
import { withWebSocket } from '../app/websocket'
import { GeneralAiIcon, TaxAiIcon } from 'icons'
import { Navigate } from 'react-router-dom'
import { chatTypeIds, practiceAreaIds, moduleIds, practiceAreas } from '@lawcyborg/packages'
import { useContext } from 'react'
import { AuthContext } from 'components/lib'
import { SettingsContext } from 'app/settings'

const NavigateToTax = () => <Navigate to={`/ai?practice_area=${practiceAreas.tax.slug}`} />
const NavigateToGeneral = () => <Navigate to="/ai/general" />

const DynamicChatView = () => {
  const auth = useContext(AuthContext)
  const settingsContext = useContext(SettingsContext)

  const availableLicensesSet = new Set(auth?.user?.userLicenses?.map((license) => license.module_id))
  const hasOnePracticeArea = Array.from(availableLicensesSet).some((licence) =>
    Object.values(practiceAreaIds).includes(licence)
  )
  const shouldHideAiGeneral = settingsContext?.settings?.disable_tool_ai_general || false

  return !hasOnePracticeArea ? (
    shouldHideAiGeneral ? (
      <Navigate to={`/account`} />
    ) : (
      <Navigate to={`/ai/general`} />
    )
  ) : availableLicensesSet.has(chatTypeIds.TAX) ? (
    <Navigate to={`/ai?practice_area=${practiceAreas.tax.slug}`} />
  ) : (
    <Navigate
      to={`/ai?practice_area=${Object.values(practiceAreas).find((pa) => availableLicensesSet.has(pa.id)).slug}`}
    />
  )
}

const options = [
  {
    label: 'General',
    icon: <GeneralAiIcon fill="currentColor" />,
    link: '/ai/general',
    id: chatTypeIds.GENERAL,
  },
  ...Object.values(practiceAreas).map((area) => ({
    label: area.name.replace('NZ ', ''),
    icon: <TaxAiIcon fill="currentColor" />,
    link: `/ai?practice_area=${area.slug}`,
    id: area.id,
  })),
]
const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },

  {
    path: '/',
    view: DynamicChatView,
    layout: 'app',
    permission: 'user',
    title: 'AI',
    header: true,
    options,
    license: moduleIds.ESSENTIAL_PLAN,
  },
  {
    path: '/ai',
    view: withWebSocket(LawCyborg),
    layout: 'app',
    permission: 'user',
    title: 'AI',
    license: moduleIds.ESSENTIAL_PLAN,
    header: true,
    options,
  },
  {
    path: '/ai/general',
    view: withWebSocket(AiGeneral),
    layout: 'app',
    permission: 'user',
    license: moduleIds.ESSENTIAL_PLAN,
    title: 'AI',
    header: true,
    options,
  },
  {
    path: '/tax-ai',
    view: NavigateToTax,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  {
    path: '/general-ai',
    view: NavigateToGeneral,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  {
    path: '/r&d-ai',
    view: withWebSocket(LawCyborg),
    layout: 'app',
    permission: 'user',
    title: 'R&D AI',
  },
  {
    path: '/tp-ai',
    view: withWebSocket(LawCyborg),
    layout: 'app',
    permission: 'user',
    title: 'Transfer Pricing AI',
  },
  {
    path: '/dr-finder',
    view: withWebSocket(DepFinder),
    layout: 'app',
    permission: 'user',
    title: 'Depreciation Rate Finder',
    header: true,
  },
  {
    path: '/prov-calc',
    view: ProvTax,
    layout: 'app',
    permission: 'user',
    title: 'Provisional Tax Calculator',
    header: true,
  },
  {
    path: '/doc-question',
    view: withWebSocket(DocUpload),
    layout: 'app',
    permission: 'user',
    title: 'Document Questioner',
    header: true,
  },
  {
    path: '/ai/rnd',
    view: Rnd,
    layout: 'app',
    permission: 'user',
    title: 'Law Cyborg - RND',
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome',
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help',
  },
]

export default Routes
