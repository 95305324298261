const MenuIcon = ({ width = 16, height = 14, fill = '#475569', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M0 12.833c0-.644.512-1.166 1.143-1.166h13.714c.631 0 1.143.522 1.143 1.166 0 .645-.512 1.167-1.143 1.167H1.143A1.155 1.155 0 0 1 0 12.833ZM0 1.167C0 .522.512 0 1.143 0h13.714C15.488 0 16 .522 16 1.167c0 .644-.512 1.166-1.143 1.166H1.143A1.155 1.155 0 0 1 0 1.167ZM0 7c0-.644.512-1.167 1.143-1.167H8c.631 0 1.143.523 1.143 1.167S8.63 8.167 8 8.167H1.143A1.155 1.155 0 0 1 0 7Z"
      clipRule="evenodd"
    />
  </svg>
)

export { MenuIcon }
