import { Table } from 'components/lib'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import html2canvas from 'html2canvas'
import DownloadablePartWrapper from './DownloadablePartWrapper'
import './tablePart.css'
const JSONTablePart = React.memo(({ message, setCopyText, index }) => {
  useEffect(() => {
    setCopyText('[TABLE]', index)
  }, [message, index, setCopyText])

  const tableRef = useRef(null)

  let json = null
  try {
    json = JSON.parse(message)
  } catch (e) {
    return <div>Could not load table.</div>
  }

  return (
    <DownloadablePartWrapper
      onClick={async (e) => {
        const table = tableRef.current
        const canvas = await html2canvas(table)
        var img = canvas.toDataURL('image/png')
        var downloadLink = document.createElement('a')
        downloadLink.href = img
        downloadLink.download = 'table.png'
        downloadLink.click()
      }}
      type="table"
    >
      <div className="table-wrapper mb-9">
        <Table data={json} search={true} sort={true} ref={tableRef} />
      </div>
    </DownloadablePartWrapper>
  )
})

export default JSONTablePart
