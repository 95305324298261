const Settings = ({ width = 18, height = 19, fill = '#475569', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" {...props}>
    <g fill="none">
      <circle cx="9" cy="16" r="2" fill="currentColor" opacity="0.16" />
      <circle cx="15" cy="8" r="2" fill="currentColor" opacity="0.16" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 8h9m4 0h3m-9 8h9M4 16h3"
      />
      <circle cx="9" cy="16" r="2" stroke="currentColor" strokeWidth="2" />
      <circle cx="15" cy="8" r="2" stroke="currentColor" strokeWidth="2" />
    </g>
  </svg>
)

export { Settings }
