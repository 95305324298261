/***
 *
 *   SETTINGS
 *   Settings provider to allow the application to synchronize,
 *   and update user settings
 *
 **********/

import { useEffect, createContext } from 'react'
import useCallAPI from 'components/hooks/callApi'

export const SettingsContext = createContext()

export function SettingsProvider(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  let storedSettings = {}
  if (user) {
    const account = user.accounts.find((account) => account.id === user.account_id)
    storedSettings = account?.settings
  }

  const [fetchSettings, settings, loading, error] = useCallAPI({
    method: 'GET',
    url: '/api/account/settings',
    initialData: storedSettings,
  })

  function isPermitted(setting) {
    if (settings) {
      return settings[setting]
    }
    return false
  }

  // LV Technically, we don't need to update the local storage
  // as the application should always access settings from here.
  // We only reference the local storage to set the initial
  // state of the settings. However, I think keeping the
  // two in sync is a good idea.
  useEffect(() => {
    if (settings) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (!user) return
      const account = user.accounts.find((account) => account.id === user.account_id)
      account.settings = settings
      localStorage.setItem('user', JSON.stringify(user))
    }
  }, [settings])

  return (
    <SettingsContext.Provider
      value={{
        settings,
        isPermitted,
        reloadSettings: fetchSettings,
        loading,
        error,
      }}
      {...props}
    />
  )
}
