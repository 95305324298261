const CrossIcon = ({ width = 13, height = 13, fill = '#475569', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width={width} height={height} fill="none" {...props}>
    <path
      fill={fill}
      d="m8.026 6.5 4.654-4.645A1.087 1.087 0 0 0 11.143.318L6.5 4.973 1.857.318A1.087 1.087 0 1 0 .32 1.855L4.974 6.5.32 11.143a1.083 1.083 0 0 0 0 1.537 1.081 1.081 0 0 0 1.537 0L6.5 8.025l4.643 4.655a1.08 1.08 0 0 0 1.537 0 1.084 1.084 0 0 0 0-1.537L8.026 6.499Z"
    />
  </svg>
)

export { CrossIcon }
