import { Contact } from 'views/website/contact'
import { Pricing } from 'views/website/pricing'

const Routes = [
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact',
  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'Pricing',
  },

]

export default Routes
