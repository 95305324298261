import { useContext } from 'react'
import { Icon, ViewContext } from 'components/lib'
import Style from './feedback.tailwind.js'
import { MessageFeedback } from './messageFeedback.js'

export function MessageFeedbackButton(props) {
  const context = useContext(ViewContext)

  return (
    <section>
      <div
        onClick={(e) =>
          context.modal.show({
            children: <MessageFeedback chatHistory={props.chatHistory}></MessageFeedback>,
          })
        }
      >
        <Icon image="alert-circle" color="grey" className={Style.icon} size={24} />
      </div>
    </section>
  )
}
