/***
 *
 *   CONCEPTS
 *   Enables an admin to manage the core concepts used in their AI workflow
 *
 **********/

import React, { Fragment, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import {
  ViewContext,
  Card,
  AccountNav,
  Table,
  Button,
  Animate,
  TitleRow,
  useAPI,
  usePermissions,
  Event,
} from 'components/lib'

export function Concepts(props) {
  //
  const context = useContext(ViewContext)
  const permissions = usePermissions()
  const data = useAPI('/api/account/concepts')
  const [concepts, setConcepts] = useState([])

  function addConcept() {
    context.modal.show(
      {
        title: 'Add Concept',
        form: {
          title: {
            label: 'title',
            type: 'text',
            required: true,
          },
          concept: {
            label: 'concept',
            type: 'text',
            required: true,
          },
          definition: {
            label: 'definition',
            type: 'text',
            required: true,
          },
          weighting: {
            label: 'weighting',
            type: 'number',
            min: 0,
            max: 10,
            required: true,
          },
        },
        buttonText: 'Add',
        text: 'Add core concept for use in your AI flow, title is the name, concept is what we check if you are talking about it, definition is what to insert when you talk about it and weighting is used to calculate whether we should provide it to the AI over other options',
        url: '/api/account/concepts',
        method: 'POST',
      },
      (form, res) => {
        if (res?.length) {
          const state = [...concepts]
          const concept = res[0]

          if (!state.find((x) => x.id === concept.id)) {
            state.push({
              id: concept.id,
              title: concept.title,
              concept: concept.concept,
              definition: concept.definition,
              weighting: concept.weighting,
            })
          }

          // Event('added_concept');
          setConcepts(state)
        }
      }
    )
  }

  function editConcept(data, callback) {
    context.modal.show(
      {
        title: 'Update Concept',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          title: {
            label: 'title',
            type: 'text',
            value: data.title,
            required: true,
          },
          concept: {
            label: 'concept',
            type: 'text',
            value: data.concept,
            required: true,
          },
          definition: {
            label: 'definition',
            type: 'text',
            value: data.definition,
            required: true,
          },
          weighting: {
            label: 'weighting',
            type: 'number',
            value: data.weighting,
            min: 0,
            max: 10,
            required: true,
          },
        },
        buttonText: 'Save',
        url: `/api/account/concepts/${data.id}`,
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(data.name + ' was updated', 'success', true)
        callback(res)
      }
    )
  }

  function deleteConcept(data, callback) {
    context.modal.show(
      {
        title: 'Delete Concept',
        form: {},
        buttonText: 'Delete',
        text: `Are you sure you want to delete ${data.title}?`,
        url: `/api/account/concepts/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(data.title + ' was deleted', 'success', true)
        callback()
      }
    )
  }

  useEffect(() => {
    // format the list
    let list = []

    if (data?.data?.concepts?.length) {
      list = data.data.concepts.map((x) => {
        return {
          id: x.id,
          title: x.title,
          concept: x.concept,
          definition: x.definition,
          weighting: x.weighting,
        }
      })
    }

    setConcepts(list)
  }, [data])

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <TitleRow title="Manage Concepts">
          <Button small text="Add Concept" action={addConcept} color={props.destructive ? 'brand3' : 'brand3'} />
        </TitleRow>

        <Card>
          <Table
            search
            className="restrict-width"
            data={concepts}
            loading={data.loading}
            show={['title', 'concept', 'definition', 'weighting']}
            actions={{
              edit: editConcept,
              delete: deleteConcept,
            }}
          />
        </Card>
      </Animate>
    </Fragment>
  )
}
