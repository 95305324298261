import React, { useEffect, useRef } from 'react'
import mermaid from 'mermaid'
import { v4 } from 'uuid'

mermaid.initialize({
  startOnLoad: true,
  theme: 'default',
  securityLevel: 'loose',
  themeCSS: `
    g.classGroup rect {
      fill: #282a36;
      stroke: #6272a4;
    } 
    g.classGroup text {
      fill: #f8f8f2;
    }
    g.classGroup line {
      stroke: #f8f8f2;
      stroke-width: 0.5;
    }
    .classLabel .box {
      stroke: #21222c;
      stroke-width: 3;
      fill: #21222c;
      opacity: 1;
    }
    .classLabel .label {
      fill: #f1fa8c;
    }
    .relation {
      stroke: #ff79c6;
      stroke-width: 1;
    }
    #compositionStart, #compositionEnd {
      fill: #bd93f9;
      stroke: #bd93f9;
      stroke-width: 1;
    }
    #aggregationEnd, #aggregationStart {
      fill: #21222c;
      stroke: #50fa7b;
      stroke-width: 1;
    }
    #dependencyStart, #dependencyEnd {
      fill: #00bcd4;
      stroke: #00bcd4;
      stroke-width: 1;
    } 
    #extensionStart, #extensionEnd {
      fill: #f8f8f2;
      stroke: #f8f8f2;
      stroke-width: 1;
    }`,
  fontFamily: 'Fira Code',
})

// LV this function will extract the valid mermaid syntax from the input string
// up to the first invalid line after a valid graph line
function removeNestedParenthesesOrSquareBrackets(input) {
  // Match a capital letter followed by '[' or '(' and content, including nested parentheses or square brackets
  let regex = /([A-Za-z][\[\(])(.*?)([\]\)](?=\s|$))/gm
  return input.replace(regex, function (match, p1, p2, p3) {
    // Remove only the nested parentheses within the brackets or parentheses
    let modifiedContent = p2.replace(/[\[\]\(\)]/g, '')
    return `${p1}${modifiedContent}${p3}`
  })
}

const Mermaid = ({ chart, forwardedRef }) => {
  let graphDiv = useRef(null)
  if (forwardedRef) {
    graphDiv = forwardedRef
  }

  // LV ensure a unique ID for each mermaid chart
  const [id] = React.useState(v4())

  useEffect(() => {
    const renderMermaid = async () => {
      const formattedChart = removeNestedParenthesesOrSquareBrackets(chart)
      if (!graphDiv.current) {
        return
      }

      try {
        const { svg, bindFunctions } = await mermaid.render(
          `mermaid-${id}`,
          `%%{
              init: {
                'theme': 'base',
                'themeVariables': {
                  'primaryColor': '#12367F',
                  'primaryTextColor': '#fff',
                  'lineColor': '#27D1FF',
                  'secondaryColor': '#006100',
                  'tertiaryColor': '#fff'
                }
              }
            }%%\n` + formattedChart,
          graphDiv.current
        )
        if (bindFunctions) {
          bindFunctions(graphDiv.current)
        }
        graphDiv.current.innerHTML = svg
      } catch (error) {
        if (graphDiv.current) {
          graphDiv.current.innerHTML = 'Invalid Mermaid syntax'
        }
      }
    }

    renderMermaid()
  }, [chart, id])

  return <div ref={graphDiv} className="mermaid mt-4 items-center flex flex-col" />
}

export default React.forwardRef((props, ref) => <Mermaid {...props} forwardedRef={ref} />)
